import {
    Box,
    InputLabel,
    Link,
    Typography,
} from "@mui/material";
import CustomButton from "components/custom-button/custom-button";
import { BLACK, BLUE, GREY, RED, WHITE } from "utils/colors";
import StepInformation from "../dashboard-reusables/step-information";
import { HEADER2 } from "utils/fonts";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import CustomTitleSelect from "components/custom-title-select/custom-title-select";
import HomeApi from "services/HomeAPi";
import Notification from "views/Notifications/Notification";
import PopUpModal from "components/pop-up-view/popup-modal";
// import { YC_NEXT_STEP, YC_NO, YC_POPUP_CONTENT, YC_PP, YC_SKIP_STEP, YC_SKIP_STEP_BUTTON,
//      YC_STEP_YP_SETUP_S3_INFO_1, YC_STEP_YP_SETUP_S3_INFO_RED_1, YC_TERMS_POLICY_TEXT, YC_TICK_CONTINUE,
//       YC_TOC, YC_UPGRADE_BUSINESS_LISTING, YC_YELLOW_PAGE_STEP_THREE_TITLE, YC_YES } from "utils/strings";
import CheckBox from "components/check-box/check-box";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
    titleText: {
        color: BLACK,
        fontSize: 20,
        fontWeight: "bold",
        marginTop: 1,
        width: '50%'
    },
}));

function YellowPageProcessThree(props: any) {
    const { titleText } = useStyles();
    const [isOpen, setIsOpen] = React.useState(false)
    const [isUpgradeBusinessListing, setIsUpgradeBusinessListing] = React.useState("")
    const [tikToContinue, setTikToContinue] = React.useState(false);
    const [isactiveButtonDisabled, setActiveButtonDisabled] = React.useState(true);

    const [inputData, setInputData] = React.useState({
        mainCategory: "",
        subCategory: "",
        childCategory: "",
        businessDescription: props.businessDescription,
        model: props.businessModel
    });

    const { t } = useTranslation()

    const validateData = () => {    
        if ( tikToContinue ) {
          setActiveButtonDisabled(false);
        } else {
          setActiveButtonDisabled(true);
        }
    
      };

    useEffect(() => {
        validateData();
      }, [ tikToContinue, isUpgradeBusinessListing]);

      const postSetupStepCount = async () => {
        const payload = {
          setup_count: 4,
          step_count: 1,
          setup_complete_per: "74.99%"
        };
    
        const response = await HomeApi.postSetupStepCount(payload);
      };

    const nextStep = async (companyDetails: any) => {
        postSetupStepCount()
        localStorage.setItem("accountingSetup", "1")
        props.goToNext("accounting_setup");
        // const payload = {
        //   country: inputData.country,
        //   region: inputData.region,
        //   town: inputData.town,
        //   address: inputData.address,
        //   postal_code: inputData.postalCode
        // };

        // const response = await HomeApi.uploadStepTwoBusinessSetup(payload);
        // if (response.status === true) {
        //   Notification.notifySuccess(response.message)
        //   props.goToNext(3);
        //   emptyData();
        // } else {
        //   Notification.notifyError(response.message)
        // }
    };



    const emptyData = () => {
        setInputData({
            mainCategory: "",
            subCategory: "",
            childCategory: "",
            businessDescription: "",
            model: ""
        })

    }

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    const handleChange = (event: any) => {
        setIsUpgradeBusinessListing(event.target.value);
      };
    
    const yellowPageInfoView = () => {
        return(
            <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: '20px',
                        marginLeft: '100px'
                    }}
                >
                    <StepInformation
                        bgColor={"rgba(15, 92, 208, 0.8)"}
                        minWidth={'100%'}
                        textColor={WHITE}
                        textOne={t("YC_STEP_YP_SETUP_S3_INFO_1")}
                    />
                    <Box sx={{ display: "flex", minWidth: 40, height: 20 }} />
                    <StepInformation
                        bgColor={RED}
                        minWidth={'100%'}
                        textColor={WHITE}
                        textOne={t("YC_STEP_YP_SETUP_S3_INFO_RED_1")}
                    />
                </Box>
        )
    }
    
    return (
        <Box
            sx={{
                m: 3,
                pr: 5,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <Typography className={titleText} sx={{ display: "flex", mb: 5 }}>
                    {t("YC_YELLOW_PAGE_STEP_THREE_TITLE")}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignContent: "flex-end",
                        width: "60%"
                    }}
                >
                    <CustomButton
                        placeHolder={t("YC_SKIP_STEP_BUTTON")}
                        textTransform="none"
                        background={GREY}
                        height="40px"
                        width={"30%"}
                        borderRadius="7.2px"
                        buttonStyle={{
                            HEADER2,
                            minWidth: "200px",
                        }}
                        onClick={togglePopup}
                    />
                </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" }, width: "100%" }}>
                <Box
                    sx={{
                        display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
                        flexDirection: "column",
                        width: "100%",
                        marginTop: 2,
                        justifyContent: 'space-between',
                        minWidth: { xs: '100%', sm: '100%', lg: '50%', xl: '50%' }
                    }}
                >
                    <CustomTitleSelect
                        boxStyle={{ width: '100%'}}
                        title={t("YC_UPGRADE_BUSINESS_LISTING")}
                        value={isUpgradeBusinessListing}
                        onChange={handleChange}
                        items={[t("YC_NO")]}
                        canSelect={true}
                    />
                    <Box sx={{ display: "flex", minWidth: 40, height: 30 }} />
                    <Box 
                     sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 6,
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "5.2px", padding: 2,
                        border: 1,
                        borderColor: BLUE,
                     }}>
                        <Typography
                            variant="subtitle1"
                            sx={{ textAlign: "center", width: "70%", lineHeight: "20px" }}
                            fontSize={16}
                        >
                            {t("YC_TERMS_POLICY_TEXT")}
                            <br /><br />
                            <Link href="#">{t("YC_TOC")}</Link>
                            {" and "}
                            <Link href="#">{t("YC_PP")}</Link>
                        </Typography>
                        <Box sx={{ display: "flex", minWidth: 80, height: 15 }} />
                        <Box
                        sx={{
                            display:'flex',
                            flexDirection:'row',
                            width: '75%',
                            justifyContent: 'space-between'
                        }}
                        >
                        <InputLabel style={{textAlign:'center', paddingTop: 5, fontWeight: 600, color: BLACK}}>
                           {t("YC_TICK_CONTINUE")}
                        </InputLabel>
                        <CheckBox
                            value="top"
                            labelPlacement="end"
                            onChange={(value: any) => {
                            setTikToContinue(value.target.checked);
                            }}
                        />
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", minWidth: 80, height: 15 }} />
                </Box>
                {yellowPageInfoView()}
            </Box>
            <div style={{ marginTop: 10 }} />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: 'row',
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "flex-end",
                    width: "98%",
                    mb: 3,
                    mt: 3
                }}
            >
                <CustomButton
                    placeHolder={`${"<" + " " + t("YC_PREVIOUS_STEP")}`}
                    textTransform="none"
                    background={GREY}
                    height="40px"
                    width={"20%"}
                    borderRadius="7.2px"
                    buttonStyle={{
                        HEADER2,
                        minWidth: "200px",
                    }}
                    onClick={() => {
                      localStorage.setItem("yellowPageStep", "2")
                        props.goToNext(2, true)
                    }}
                />
                <CustomButton
                    disabled={isactiveButtonDisabled}
                    placeHolder={t("YC_NEXT_STEP")}
                    textTransform="none"
                    background={isactiveButtonDisabled ? GREY : BLUE}
                    height="40px"
                    width={"20%"}
                    borderRadius="7.2px"
                    buttonStyle={{
                        HEADER2,
                        minWidth: "200px",
                    }}
                    onClick={() => {
                        nextStep(inputData)
                    }}
                />
            </Box>
            {/* <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    alignContent: "flex-end",
                    width: "100%",
                    mb: 3,
                    mt: 3
                }}
            >
                <CustomButton
                    disabled={isactiveButtonDisabled}
                    placeHolder={t("YC_NEXT_STEP")}
                    textTransform="none"
                    background={isactiveButtonDisabled ? GREY : BLUE}
                    height="40px"
                    width={"20%"}
                    borderRadius="7.2px"
                    buttonStyle={{
                        HEADER2,
                        minWidth: "200px",
                    }}
                    onClick={() => {
                        nextStep(inputData)
                    }}
                />
            </Box> */}
            {isOpen && <PopUpModal
                title={t("YC_SKIP_STEP")}
                description={t("YC_POPUP_CONTENT")}
                handleClose={togglePopup}
                firstbuttonName={t("YC_YES")}
                secondbuttonName={t("YC_NO")}
                firstbuttonAction={() => {
                    postSetupStepCount()
                    localStorage.setItem("accountingSetup", "1")
                    props.goToNext("accounting_setup");
                }}
                secondbuttonAction={() => {
                    togglePopup()
                }}
            />}
        </Box>
    );
}
export default YellowPageProcessThree;
