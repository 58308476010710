import {
    Box,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography,
  } from "@mui/material";
  import CustomButton from "components/custom-button/custom-button";
  import CustomSelect from "components/custom-select/custom-select";
  import { BLACK, BLUE, GREY, WHITE } from "utils/colors";
  import StepInformation from "../dashboard-reusables/step-information";
  import { HEADER2 } from "utils/fonts";
  import { makeStyles } from "@mui/styles";
  import React, { useEffect } from "react";
  import SuccessIcon from "../../../assets/success.png";
  import Error from "../../../assets/error.png";
  import HomeApi from "services/HomeAPi";
  import Notification from "views/Notifications/Notification";
  import { validateEmail, validateFaxNumber, validateMobilePhone } from "utils/helper";
  // import { UC_BUSINESS_SETUP_STEP_THREE_INFO_1, UC_CONTACT_EMAIL, UC_FIX_LINE_NUMBER, YC_BUSINESS_SETUP_STEP_THREE_TITLE, YC_COUNTRY_CODE, YC_EMAIL_VALID, YC_FAX_VALID, YC_LANDLINE_VALID, YC_MOBILE_NUMBER, YC_PHONE_VALID } from "utils/strings";
  import ErrorExclamation from "../../../assets/exclamation-mark.png";
import { useTranslation } from "react-i18next";
  
  const useStyles = makeStyles(() => ({
    titleText: {
      color: BLACK,
      fontSize: 20,
      fontWeight: "bold",
      marginTop: 1,
    },
  }));
  
  function BusinessProcessThree(props: any) {
    const { titleText } = useStyles();
    const [inputData, setInputData] = React.useState({
      countryCode: "",
      faxPhoneNumber: "",
      phone: "",
      mobile: "",
      fax: "",
      email: "",
      facebook: "",
      linkedin: "",
      instagram: "",
      pinterest: "",
      youTube: ""
    });
    const [isVatRegistered, setIsVatRegistered] = React.useState("");
    const [isNextStepDisable, setIsNextStepDisable] = React.useState(true)
    const [isValidFixLineNumber1, setIsValidFixLineNumber1] = React.useState({value: true, error: ""})
    const [isValidFixLineNumber2, setIsValidFixLineNumber2] = React.useState({value: true, error: ""})
    const [isValidMobileNumber, setIsValidMobileNumber] = React.useState({value: true, error: ""})
    const [isValidFaxNumber, setIsValidFaxNumber] = React.useState({value: true, error: ""})
    const [isValidEmail, setIsValidEmail] = React.useState({value: true, error: ""})

    const { t } = useTranslation()

    useEffect(() => {
      getBusinessSetupData()
    }, [])

    useEffect(() => {
      validateForNextStep()
    }, [inputData.countryCode, inputData.mobile, inputData.faxPhoneNumber, inputData.phone])
  
    const getUserId = () => {
      let userId;
      const loginUserDetails = localStorage.getItem("user_Details")
      if (loginUserDetails){
        const details = JSON.parse(loginUserDetails)
        userId = details.id
      }
      return userId
    }

  const validateForNextStep = () => {
  if (inputData.countryCode && (inputData.faxPhoneNumber || inputData.phone || inputData.mobile)) {
    if (( inputData.mobile && isValidMobileNumber.value) 
      || (inputData.faxPhoneNumber  && isValidFixLineNumber1.value) 
      || ( inputData.phone && isValidFixLineNumber2.value) ) {
        setIsNextStepDisable(false)
     } else {
        setIsNextStepDisable(true)
     }
  } else {
      setIsNextStepDisable(true)
  }
  }
  
  const nextStep = async (companyDetails: any) => {
    localStorage.setItem("businessStep", "4")
    let userId;
    const loginUserDetails = localStorage.getItem("user_Details")
    if (loginUserDetails){
      const details = JSON.parse(loginUserDetails)
      userId = details.id
    }
    const payload = {
      country_code: inputData.countryCode,
      fax_phone_number : inputData.faxPhoneNumber,
      phone : inputData.phone,
      mobile: inputData.mobile,
      email: inputData.email,
      facebook: inputData.facebook,
      linkedin: inputData.linkedin,
      instagram: inputData.instagram,
      pinterest: inputData.pinterest,
      youtube: inputData.youTube,
      setup_count: 2,
      step_count: 4,
      setup_complete_per: "43.75%"
    };
  
    const response = await HomeApi.uploadStepThreeBusinessSetup(payload, userId);
  
    if (response.status === true) {
      Notification.notifySuccess(response.message)
      emptyData();
      props.isFromDashBoard ?  props.setIsShowBusinessStepThreeModal(false) : props.goToNext(4);
    } else {
      Notification.notifyError(response.message)
    }
  }; 
  
  const getBusinessSetupData = async () => {
    const response = await HomeApi.getBusinessSetupData(getUserId())
    if(response.status === true){
      setInputData({
        countryCode: response.data.counry_code,
        faxPhoneNumber: response.data.fax_phone_number,
        phone: response.data.phone,
        mobile: response.data.mobile,
        fax: response.data.fax,
        email: response.data.email,
        facebook: response.data.facebook,
        linkedin: response.data.linkedin,
        instagram: response.data.instagram,
        pinterest: response.data.pinterest,
        youTube: response.data.youtube
      })
    }
  }
  
  const emptyData = () => {
    setInputData({
        countryCode: "",
        faxPhoneNumber: "",
        phone: "",
        mobile: "",
        fax: "",
        email: "",
        facebook: "",
        linkedin: "",
        instagram: "",
        pinterest: "",
        youTube: ""
    })
    
  }
  
    const renderIcon = (iconName: any) => {
      return (
          <img
            src={iconName}
            height={iconName === ErrorExclamation ? 35 : 22}
            alt={"icon"}
          />
      );
    };
  
    const textFieldView = (key: string, label: string = "", value: string) => {
      return (
        <Box sx={{width: key === "country_code" || key === "faxPhoneNumber" || key === "phone" || key === "mobile" ? 
          { xs: "100%", sm: "100%", lg: "42.5%", xl: "42.5%" }
          : { xs: "100%", sm: "80%", lg: "90%", xl: "90%" }
        }}>
          <FormControl
            sx={{
              mb: 1,
              width: "100%",
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.07)",
              background: WHITE,
            }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
            <OutlinedInput
            endAdornment={
                value?.length === 0 ? (<InputAdornment position="end">
                {renderIcon(ErrorExclamation)}
              </InputAdornment>) :
               ((isValidFixLineNumber1.error && key === "faxPhoneNumber") ||
                 (isValidFixLineNumber2.error && key === "phone") ||
                 (isValidMobileNumber.error && key === "mobile") ||
                 (isValidFaxNumber.error && key === "fax") || 
                 (isValidEmail.error && key === "email")) ?
                <InputAdornment position="end">
                  {renderIcon(Error)}
                </InputAdornment> :
                <InputAdornment position="end">
                {renderIcon(SuccessIcon)}
              </InputAdornment>
              }
              label={label}
              value={value}
              disabled={false}
              onChange={(text) => {
                const trimStr = text.target.value.trimStart();
                if (key === "country_code") {
                  setInputData((prevFields) => ({
                    ...prevFields,
                    countryCode: trimStr,
                  }));
                } else if (key === "faxPhoneNumber") {
                   const fax = text.target.value.replace(/(\d{3})(\d{2})(\d{2})/, "$1 $2 $3");
                   const isValidNumber = validateFaxNumber(fax);
                   setIsValidFixLineNumber1({value:isValidNumber, error: !isValidNumber ? t("YC_LANDLINE_VALID") : ""})
                   setInputData((prevFields) => ({
                    ...prevFields,
                    faxPhoneNumber: trimStr,
                   }));
                } else if (key === "phone") {
                  const fax = text.target.value.replace(/(\d{3})(\d{2})(\d{2})/, "$1 $2 $3");
                  const isValidNumber = validateFaxNumber(fax);
                  setIsValidFixLineNumber2({value:isValidNumber, error: !isValidNumber ? t("YC_LANDLINE_VALID") : ""})
                  setInputData((prevFields) => ({
                   ...prevFields,
                   phone: trimStr,
                  }));
               } else if (key === "mobile") {
                    const mob = text.target.value.replace(/(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4");
                    const isValidNumber = validateMobilePhone(mob);
                    setIsValidMobileNumber({value:isValidNumber, error: !isValidNumber ? t("YC_PHONE_VALID") : ""})
                    setInputData((prevFields) => ({
                      ...prevFields,
                      mobile: trimStr,
                    }));
                } else if (key === "fax") {
                    const fax = text.target.value.replace(/(\d{3})(\d{2})(\d{2})/, "$1 $2 $3");
                    const isValidNumber = validateFaxNumber(fax);
                    setIsValidFaxNumber({value:isValidNumber, error: !isValidNumber ? t("YC_FAX_VALID") : ""})
                    setInputData((prevFields) => ({
                     ...prevFields,
                     fax: trimStr,
                    }));
                } else if (key === "email") {
                  const validEmail = validateEmail(value);
                setIsValidEmail({value: validEmail, error: !validEmail ? t("YC_EMAIL_VALID") : ""})
                    setInputData((prevFields) => ({
                      ...prevFields,
                      email: trimStr,
                    }));
                  } else if (key === "facebook") {
                    setInputData((prevFields) => ({
                      ...prevFields,
                      facebook: trimStr,
                    }));
                  } else if (key === "linkedin") {
                    setInputData((prevFields) => ({
                      ...prevFields,
                      linkedin: trimStr,
                    }));
                  } else if (key === "instagram") {
                    setInputData((prevFields) => ({
                      ...prevFields,
                      instagram: trimStr,
                    }));
                  } else if (key === "pinterest") {
                    setInputData((prevFields) => ({
                      ...prevFields,
                      pinterest: trimStr,
                    }));
                  } else if (key === "youtube") {
                    setInputData((prevFields) => ({
                      ...prevFields,
                      youTube: trimStr,
                    }));
                  }
              }}
              error={(!!isValidFixLineNumber1.error && key === "faxPhoneNumber") ||
                     (!!isValidFixLineNumber2.error && key === "phone") || 
                     (!!isValidMobileNumber.error && key === "mobile") ||
                     (!!isValidFaxNumber.error && key === "fax") ||
                     (!!isValidEmail.error && key === "email")}
         />
         {!!isValidFixLineNumber1.error && key === "faxPhoneNumber" && (
             <FormHelperText error id="validNumber-error">
               {isValidFixLineNumber1.error}
             </FormHelperText>
           )}
            {!!isValidFixLineNumber2.error && key === "phone" && (
             <FormHelperText error id="validNumber-error">
               {isValidFixLineNumber2.error}
             </FormHelperText>
           )}
           {!!isValidMobileNumber.error && key === "mobile" && (
             <FormHelperText error id="validMobileNumber-error">
               {isValidMobileNumber.error}
             </FormHelperText>
           )}
           {!!isValidFaxNumber.error && key === "fax" && (
             <FormHelperText error id="validFax-error">
               {isValidFaxNumber.error}
             </FormHelperText>
           )}
           {!!isValidEmail.error && key === "email" && (
            <FormHelperText error id="validEmail-error">
              {isValidEmail.error}
            </FormHelperText>
          )}
       </FormControl>
        </Box>
      );
    };
  
    const dummyView = () => {
      return <Box sx={{ display: "flex", minWidth: 80, height: 5 }} />;
    };

    const dummyViewForRow = () => {
      return <Box sx={{ display: "flex", minWidth: 30, height: 5 }} />;
    };
  
    return (
      <Box
        sx={{
          m: 3,
          pr: 5,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography className={titleText} sx={{ display: "flex", mb: 5 }}>
        {t("YC_BUSINESS_SETUP_STEP_THREE_TITLE")}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%"}}>
          <Box
            sx={{
              display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
              flexDirection: "column",
              width: "100%",
              minWidth: { xs: "100%", sm: "0%", lg: "0%", xl: "0%" }
            }}
          >
            <Box sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'column0', lg: 'row', xl: 'row' }, width: "100%"}}>
                {textFieldView(
                  "country_code",
                  t("YC_COUNTRY_CODE"),
                  inputData.countryCode
                )}
                {dummyViewForRow()}
                {textFieldView(
                  "faxPhoneNumber",
                  t("YC_FAX_PHONE_NUMBER"),
                  inputData.faxPhoneNumber
                )}
            </Box>
            {dummyView()}
            <Box sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'column0', lg: 'row', xl: 'row' }, width: "100%"}}>
                {textFieldView(
                  "phone",
                  t("YC_PHONE"),
                  inputData.phone
                )}
                {dummyViewForRow()}
                {textFieldView(
                  "mobile",
                  t("YC_MOBILE_NUMBER"),
                  inputData.mobile
                )}
            </Box>
            {dummyView()}
            {textFieldView(
              "email",
              t("UC_CONTACT_EMAIL"),
              inputData.email
            )}
            {dummyView()}
          </Box>
          <StepInformation
            // boxStyle={{ marginTop: "10px" }}
            width={"95%"}
            minWidth={"40%"}
            bgColor={"rgba(15, 92, 208, 0.8)"}
            textColor={WHITE}
            textOne={t("UC_BUSINESS_SETUP_STEP_THREE_INFO_1")}
          />
          {dummyView()}
        </Box>
        <Box sx={{height: { xs: 0, sm: 10, lg: 10, xl: 10 }}}/>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row", lg: "row", xl: "row" },
            width: "100%"
          }}
        > 
        <Box sx={{width: { xs: "100%", sm: "100%", lg: "56%", xl: "56%" }}}>
        <Box
            sx={{
              display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
              flexDirection: "column",
              width: "100%",
            }}
          >
            {textFieldView(
              "facebook",
              "Facebook",
              inputData.facebook
            )}
            {dummyView()}
            {textFieldView(
              "instagram",
              "Instagram",
              inputData.instagram
            )}
            {dummyView()}
            {textFieldView(
              "pinterest",
              "Pinterest",
              inputData.pinterest
            )}
            {dummyView()}
          </Box>
        </Box>
        <Box sx={{width:{ xs: "100%", sm: "100%", lg: "50%", xl: "50%" }}}>  
          <Box
           sx={{
            display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
            flexDirection: "column",
            width: { xs: "100%", sm: "100%", lg: "106%", xl: "100%" },
          }}
          >
            {textFieldView(
              "linkedin",
              "Linkedin",
              inputData.linkedin
            )}
            {dummyView()}
            {textFieldView(
              "youtube",
              "Youtube",
              inputData.youTube
            )}
            {dummyView()}
          </Box>
          </Box>
        </Box>
        <Box
        sx={{
          display: "flex",
          flexDirection: 'row',
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "flex-end",
          width: "98%",
          mb: 3,
          mt: 3
        }}
      >
        {!props.isFromDashBoard && (<CustomButton
          placeHolder={`${"<" + " " +t("YC_PREVIOUS_STEP")}`}
          textTransform="none"
          background={GREY}
          height="40px"
          width={"20%"}
          borderRadius="7.2px"
          buttonStyle={{
            HEADER2,
            minWidth: "200px",
          }}
          onClick={() => {
            localStorage.setItem("businessStep", "2")
            props.goToNext(2, true)
          }}
        />
        )}
        <CustomButton
            disabled={isNextStepDisable}
            placeHolder={!props.isFromDashBoard ? t("YC_NEXT_STEP") : t("YC_SUBMIT")}
            textTransform="none"
            background={isNextStepDisable ? GREY : BLUE}
            height="40px"
            width={"20%"}
            borderRadius="7.2px"
            buttonStyle={{
              HEADER2,
              minWidth: "200px",
            }}
            onClick={() => {
              nextStep(inputData)
            }}
          />
      </Box>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            alignContent: "flex-end",
            width: "96%",
            mb: 3,
            mt: 3
          }}
        >
          <CustomButton
            disabled={isNextStepDisable}
            placeHolder={t("YC_NEXT_STEP")}
            textTransform="none"
            background={isNextStepDisable ? GREY : BLUE}
            height="40px"
            width={"20%"}
            borderRadius="7.2px"
            buttonStyle={{
              HEADER2,
              minWidth: "200px",
            }}
            onClick={() => {
              nextStep(inputData)
            }}
          />
        </Box> */}
      </Box>
    );
  }
  export default BusinessProcessThree;
  