import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import CustomButton from "components/custom-button/custom-button";
import CustomSelect from "components/custom-select/custom-select";
import { BLACK, BLUE, GREY, RED, WHITE } from "utils/colors";
import StepInformation from "../dashboard-reusables/step-information";
import { HEADER2 } from "utils/fonts";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import SuccessIcon from "../../../assets/success.png";
import ErrorExclamation from "../../../assets/exclamation-mark.png";
import CustomTitleSelect from "components/custom-title-select/custom-title-select";
import HomeApi from "services/HomeAPi";
import Notification from "views/Notifications/Notification";
import PopUpModal from "components/pop-up-view/popup-modal";
import { BorderColor } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
// import {
//   YC_1_CATEGORY, YC_50_CHARACTER, YC_BUSINESS_DESCRIPTION_QUESTION, YC_BUSINESS_MODULE_QUESTION, YC_BUSINESS_NATURE_QUESTION,
//   YC_DESCRIPTION_MAX_50, YC_NEXT_STEP, YC_NO, YC_POPUP_CONTENT, YC_SELECT_CATEGORY, YC_SELECT_CHILD_CATEGORY,
//   YC_SELECT_MODEL, YC_SELECT_SUB_CATEGORY, YC_SKIP_STEP, YC_SKIP_STEP_BUTTON,
//   YC_STEP_INFO_FOUR_YP_SETUP,
//   YC_STEP_INFO_ONE_YP_SETUP,
//   YC_STEP_INFO_THREE_YP_SETUP,
//   YC_STEP_INFO_TWO_YP_SETUP, YC_YELLOW_PAGE_STEP_ONE_TITLE, YC_YES
// } from "utils/strings";

const useStyles = makeStyles(() => ({
  titleText: {
    color: BLACK,
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 1,
    width: '50%'
  },
}));

function YellowPageProcessOne(props: any) {
  const { titleText } = useStyles();
  const [isOpen, setIsOpen] = React.useState(false)

  const [mainCategoryList, setMainCategoryList] = React.useState([])
  const [mainCategoryNameList, setMainCategoryNameList] = React.useState([])
  const [mainCategoryId, setMainCategoryId] = React.useState(props.mainCategoryId)

  const [subCategoryList, setSubCategoryList] = React.useState([])
  const [subCategoryNameList, setSubCategoryNameList] = React.useState([])

  const [childCategoryList, setChildCategoryList] = React.useState([])
  const [childCategoryNameList, setChildCategoryNameList] = React.useState([])

  const [businessModelList, setBusinessModelList] = React.useState([])
  const [businessModelNameList, setBusinessModelNameList] = React.useState([])
  const [mainCategoryID,setMainCategoryID] = React.useState(0)
  const [subCategoryID, setSubCategoryID] = React.useState(0)
  const [childCategoryID, setChildCategoryID] = React.useState(0)

  const { t } = useTranslation()

  const [inputData, setInputData] = React.useState({
    mainCategory: "",
    subCategory: "",
    childCategory: "",
    businessDescription: "",
  });

  useEffect(() => {
    getMainCategoryList()
    getBusinessModelData()
    getYellowPageData()
    // if (props.mainCategoryId !== 0) {
    //     getSubCategoryList(props.mainCategoryId)
    // }
  }, [])

  // const validateForNextStep = () => {
  //   if (inputData.country && inputData.region && inputData.town && inputData.address) {
  //     setIsNextStepDisable(false)
  //   } else {
  //     setIsNextStepDisable(true)
  //   }
  // }

  const getMainCategoryList = async () => {
    const response = await HomeApi.getMainCategoryData();
    if (response.status === true) {
      setMainCategoryList(response.data)
      let tempArray: any = [];
      response.data.map((element: any) => {
        tempArray.push(element.content)
      });
      setMainCategoryNameList(tempArray)
    }
  }

  const getSubCategoryList = async (mainCategoryId: string) => {
    const response = await HomeApi.getSubCategoryData(mainCategoryId);
    if (response.status === true) {
      setSubCategoryList(response.data)
      let tempArray: any = [];
      response.data.map((element: any) => {
        tempArray.push(element.content)
      });
      setSubCategoryNameList(tempArray)
    }
  }

  const getChildCategoryList = async (mainCategoryId: string, subCategoryId: string) => {
    const response = await HomeApi.getChildCategoryData(mainCategoryId, subCategoryId);
    if (response.status === true) {
      setChildCategoryList(response.data)
      let tempArray: any = [];
      response.data.map((element: any) => {
        tempArray.push(element.content)
      });
      setChildCategoryNameList(tempArray)
    }
  }

  const getUserId = () => {
    let userId;
    const loginUserDetails = localStorage.getItem("user_Details")
    if (loginUserDetails){
      const details = JSON.parse(loginUserDetails)
      userId = details.id
    }
    return userId
  }

  const getYellowPageData = async () => {
    const response = await HomeApi.getYellowPageSetupData(getUserId());
    if (response.status === true) {
     getSubCategoryList(response.data.main_category)
     getChildCategoryList(response.data.main_category, response.data.sub_category)

      setMainCategoryId(response.data.main_category)
      setMainCategoryID(response.data.main_category)
      setSubCategoryID(response.data.sub_category)
      setChildCategoryID(response.data.child_category)
      setInputData({
        mainCategory: response.data.main_category_name,
        subCategory: response.data.sub_category_name,
        childCategory: response.data.child_category_name,
        businessDescription: response.data.businessdescribtion
      })

    }
  }

  const clearCategoryData = () => {
    setSubCategoryList([])
    setSubCategoryNameList([])
    setChildCategoryList([])
    setChildCategoryNameList([])
  }

  const getBusinessModelData = async () => {
    const response = await HomeApi.getBusinessModuleData();
    if (response.status === true) {
      setBusinessModelList(response.data)
      let tempArray: any = [];
      response.data.map((element: any) => {
        tempArray.push(element.module_name)
      });
      setBusinessModelNameList(tempArray)
    }
  }

  const postSetupStepCount = async () => {
    const payload = {
      setup_count: 3,
      step_count: 2
    };

    const response = await HomeApi.postSetupStepCount(payload);
  };

  const nextStep = async (companyDetails: any) => {
    localStorage.setItem("yellowPageStep", "2")
    let userId;
    const loginUserDetails = localStorage.getItem("user_Details")
    if (loginUserDetails){
      const details = JSON.parse(loginUserDetails)
      userId = details.id
    }
    const payload = {
      businessdescribtion: inputData.businessDescription,
      main_category: mainCategoryID,
      sub_category: subCategoryID,
      child_category: childCategoryID,
      setup_count: 3,
      step_count: 2,
      setup_complete_per: "58.33%"
    };

    const response = await HomeApi.uploadYellowPageFirstStepData(payload, userId);
    if (response.status === true) {
      Notification.notifySuccess(response.message)
      props.goToNext(2);
      emptyData();
    } else {
      Notification.notifyError(response.message)
    }
  };



  const emptyData = () => {
    setInputData({
      mainCategory: "",
      subCategory: "",
      childCategory: "",
      businessDescription: ""
    })

  }

  const togglePopup = () => {
    setIsOpen(!isOpen);
  }

  const dummyView = () => {
    return <Box sx={{ display: "flex", minWidth: 80, height: 3 }} />;
  };

  const dummyView2 = () => {
    return <Box sx={{ display: "flex", minWidth: 80, height: 25 }} />;
  };

  const selectionView = (
    key: any,
    items: any,
    value: any,
    label: any) => {
    return (
      <Box sx={{ width: "100%" }}>
        <CustomSelect
          label={label}
          minWidth={"100%"}
          items={items}
          value={value}
          isShowTick={true}
          isDataNotField={value === "" ? true : false}
          onChange={(event: any) => {
            if (key === "maincategory") {
              clearCategoryData()
              setInputData((prevFields) => ({
                ...prevFields,
                mainCategory: event.target.value,
              }))
              const selectedCategory: any = mainCategoryNameList.filter((name: any) => (name === event.target.value))
              mainCategoryList.map((category: any) => {
                if (category.content === selectedCategory[0]) {
                  setMainCategoryId(category.elementid)
                  getSubCategoryList(category.elementid)
                  setMainCategoryID(category.elementid)
                }
              })
            } else if (key === "subcategory") {
              setInputData((prevFields) => ({
                ...prevFields,
                subCategory: event.target.value,
              }))

              const selectedCategory: any = subCategoryNameList.filter((name: any) => (name === event.target.value))
              subCategoryList.map((category: any) => {
                if (category.content === selectedCategory[0]) {
                  getChildCategoryList(mainCategoryId, category.elementid)
                  setSubCategoryID(category.elementid)
                }
              })
            } else if (key === "childcategory") {
              const selectedChildCategory: any = childCategoryNameList.filter((name: any) => (name === event.target.value))
              childCategoryList.map((category: any) => {
                if (category.content === selectedChildCategory[0]) {
                  setChildCategoryID(category.elementid)
                }
              })
              setInputData((prevFields) => ({
                ...prevFields,
                childCategory: event.target.value,
              }))
            }
          }}
        />
      </Box>
    )
  }

  const yellowPageInformationView = () => {
    return (
      <Box
        sx={{
          display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
          flexDirection: "column",
          marginTop: '20px',
          marginLeft: '50px'
        }}
      >
        <StepInformation
          bgColor={"rgba(15, 92, 208, 0.8)"}
          minWidth={'100%'}
          textColor={WHITE}
          textOne={t("YC_STEP_INFO_ONE_YP_SETUP")}
          textTwo={t("YC_STEP_INFO_TWO_YP_SETUP")}
          textThree={t("YC_STEP_INFO_THREE_YP_SETUP")}
          textFour={t("YC_STEP_INFO_FOUR_YP_SETUP")}
        />
      </Box>
    )
  }

  const renderIcon = (iconName: any) => {
    return (
      <img
        src={iconName}
        height={iconName === ErrorExclamation ? 35 : 22}
        alt={"icon"}
      />
    );
  };

  const textFieldView = (key: string, label: string = "", value: string) => {
    return (
      <Box>
        <FormControl
          sx={{
            width: "100%",
            boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.07)",
            background: WHITE,
          }}
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
          <OutlinedInput
            endAdornment={
              value?.length === 0 ? (<InputAdornment position="end">
                {renderIcon(ErrorExclamation)}
              </InputAdornment>) :
                <InputAdornment position="end">
                  {renderIcon(SuccessIcon)}
                </InputAdornment>
            }
            label={label}
            value={value}
            inputProps={{
              maxLength: 50,
            }}
            onChange={(text) => {
              const trimStr = text.target.value.trimStart();
              if (key === "business_description") {
                setInputData((prevFields) => ({
                  ...prevFields,
                  businessDescription: trimStr,
                }));
                props.setBusinessDescription(text.target.value)
              }
            }}
          />
        </FormControl>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        m: 3,
        pr: 5,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <Typography className={titleText} sx={{ display: "flex", mb: 5 }}>
          {t("YC_YELLOW_PAGE_STEP_ONE_TITLE")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "flex-end",
            width: "60%"
          }}
        >
          <CustomButton
            placeHolder={t("YC_SKIP_STEP_BUTTON")}
            textTransform="none"
            background={GREY}
            height="40px"
            width={"30%"}
            borderRadius="7.2px"
            buttonStyle={{
              HEADER2,
              minWidth: "200px",
            }}
            onClick={togglePopup}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" }, width: "100%" }}>
        <Box
          sx={{
            display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
            flexDirection: "column",
            width: "80%",
            minWidth: { xs: '100%', sm: '100%', lg: '20%', xl: '20%' }
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <InputLabel style={{ color: 'black', textAlign: 'start', marginLeft: 10 }}>{t("YC_BUSINESS_DESCRIPTION_QUESTION")}</InputLabel>
            {dummyView()}
            {textFieldView(
              "business_description",
              t("YC_DESCRIPTION_MAX_50"),
              inputData.businessDescription
            )}
            <InputLabel style={{ color: 'red', textAlign: 'end' }}>{`${inputData.businessDescription.length + " " + t("YC_50_CHARACTER")}`} </InputLabel>
          </Box>
          {dummyView2()}
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <InputLabel style={{ color: 'black', textAlign: 'start', marginLeft: 10 }}>{t("YC_BUSINESS_NATURE_QUESTION")}</InputLabel>
            {selectionView("maincategory", mainCategoryNameList, inputData.mainCategory, inputData.mainCategory ? t("YC_SELECT_CATEGORY") : `${t("YC_SELECT_CATEGORY") + " " + '*'}`)}
            <InputLabel style={{ color: 'red', textAlign: 'end' }}>{`${(inputData.mainCategory === "" ? 0 : 1) + " " + t("YC_1_CATEGORY")}`} </InputLabel>
          </Box>
          {dummyView2()}
          {(inputData.subCategory !== "" || subCategoryList?.length > 0) && (
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              {selectionView("subcategory", subCategoryNameList, inputData.subCategory, t("YC_SELECT_SUB_CATEGORY"))}
              <InputLabel style={{ color: 'red', textAlign: 'end' }}>{`${(inputData.subCategory === "" ? 0 : 1) + " " + t("YC_1_CATEGORY")}`} </InputLabel>
              {dummyView2()}
            </Box>
          )
          }
          {(inputData.childCategory !== "" || childCategoryList?.length > 0) && (
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              {selectionView("childcategory", childCategoryNameList, inputData.childCategory, t("YC_SELECT_CHILD_CATEGORY"))}
              <InputLabel style={{ color: 'red', textAlign: 'end' }}>{`${(inputData.childCategory === "" ? 0 : 1) + " " + t("YC_1_CATEGORY")}`} </InputLabel>
              {dummyView2()}
            </Box>
          )
          }
        </Box>
        {yellowPageInformationView()}
      </Box>
      <div style={{ marginTop: 10 }} />
      <Box
                sx={{
                    display: "flex",
                    flexDirection: 'row',
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "flex-end",
                    width: "98%",
                    mb: 3,
                    mt: 3
                }}
            >
                <CustomButton
                    placeHolder={`${"<" + " " + t("YC_PREVIOUS_STEP")}`}
                    textTransform="none"
                    background={GREY}
                    height="40px"
                    width={"20%"}
                    borderRadius="7.2px"
                    buttonStyle={{
                        HEADER2,
                        minWidth: "200px",
                    }}
                    onClick={() => {
                        localStorage.setItem("businessStep", "4")
                        props.goToNext("business_setup", true)
                    }}
                />
                <CustomButton
                  placeHolder={t("YC_NEXT_STEP")}
                  textTransform="none"
                  background={BLUE}
                  height="40px"
                  width={"20%"}
                  borderRadius="7.2px"
                  buttonStyle={{
                    HEADER2,
                    minWidth: "200px",
                  }}
                  onClick={() => {
                    nextStep(inputData)
                  }}
                />
            </Box>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          alignContent: "flex-end",
          width: "100%",
          mb: 3,
          mt: 3
        }}
      >
        <CustomButton
          // disabled={isNextStepDisable}
          placeHolder={t("YC_NEXT_STEP")}
          textTransform="none"
          background={BLUE}
          height="40px"
          width={"20%"}
          borderRadius="7.2px"
          buttonStyle={{
            HEADER2,
            minWidth: "200px",
          }}
          onClick={() => {
            nextStep(inputData)
          }}
        />
      </Box> */}
      {isOpen && <PopUpModal
        title={t("YC_SKIP_STEP")}
        description={t("YC_POPUP_CONTENT")}
        handleClose={togglePopup}
        firstbuttonName={t("YC_YES")}
        secondbuttonName={t("YC_NO")}
        firstbuttonAction={() => {
          postSetupStepCount()
          localStorage.setItem("yellowPageStep", "2")
          props.goToNext(2);
        }}
        secondbuttonAction={() => {
          togglePopup()
        }}
      />}
    </Box>
  );
}
export default YellowPageProcessOne;
