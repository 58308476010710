import {
    Box,
    Button,
    FormControl,
    InputAdornment,
    InputLabel,
    Link,
    Modal,
    OutlinedInput,
    Typography,
} from "@mui/material";
import CustomButton from "components/custom-button/custom-button";
import CustomSelect from "components/custom-select/custom-select";
import { BLACK, BLUE, GREEN, GREY, LINK, RED, WHITE } from "utils/colors";
import StepInformation from "../dashboard-reusables/step-information";
import { HEADER2 } from "utils/fonts";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import SuccessIcon from "../../../assets/success.png";
import ErrorExclamation from "../../../assets/exclamation-mark.png";
import CustomTitleSelect from "components/custom-title-select/custom-title-select";
import HomeApi from "services/HomeAPi";
import Notification from "views/Notifications/Notification";
import PopUpModal from "components/pop-up-view/popup-modal";
import { BorderColor } from "@mui/icons-material";
// import {
//     YC_ACCOUNTING_SETUP_POP_UP_TEXT,
//     YC_ACCOUNTING_STEP_TWO_TITLE, YC_DELIVERY_TERMS, YC_GIVE_INVOICE_TEMPLATE_TITLE, YC_NEXT_STEP, YC_NO,
//     YC_PAYMANT_METHOD_AVAILABLE, YC_PAYMENT_TERMS, YC_POPUP_CONTENT, YC_SKIP_STEP, YC_SKIP_STEP_BUTTON,
//     YC_STEP_ACCOUNTING_SETUP_S3_INFO_1, YC_STEP_ACCOUNTING_SETUP_S3_INFO_2, YC_STEP_ACCOUNTING_SETUP_S3_INFO_RED_1,
//     YC_TC_UPLOADED,
//     YC_TERMS_CONDITION_LINK, YC_YELLOW_PAGE_STEP_FOUR_TITLE, YC_YES
// } from "utils/strings";
import FileUpload from "components/file-uploader/image-uploader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "utils/helper";
import NewFileUploadModelView from "./upload-file-dailog";
import PdfView from "../business-setup/pdfView";

const useStyles = makeStyles(() => ({
    titleText: {
        color: BLACK,
        fontSize: 20,
        fontWeight: "bold",
        marginTop: 1,
        width: '50%'
    },
}));

function AccountingProcessThree(props: any) {
    const { titleText } = useStyles();
    const [isOpen, setIsOpen] = React.useState(false)
    const [isNextStepDisable, setIsNextStepDisable] = React.useState(true)
    const [isDocNameAvailable, setIsDocNameAvailable] = React.useState(true)
    const [docsList, setDocsList] = React.useState([])
    const [docsNameList, setDocsNameList] = React.useState([])
    const [documentName, setDocumentName] = React.useState("");
    const [documentUpload, setDocumentUpload] = React.useState("");
    const [isShowModal, setIsShowModal] = React.useState(false);
    const [isShowPolicyModal, setIsShowPolicyModal] = React.useState(false);
    
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [inputData, setInputData] = React.useState({
        giveInvoiceTitle: "",
        paymentTerms: "",
        paymentMethod: "",
        termsConditionLink: "",
        termsConditionDocId: "",
        // tcFileUploaded: "",
        deliveryTerm: ""
    });
   
    useEffect (() => {
        getAccountingData()
    }, [])

    const getUserId = () => {
        let userId;
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails){
          const details = JSON.parse(loginUserDetails)
          userId = details.id
        }
        return userId
      }
    
      const getAccountingData = async () => {
        const response = await HomeApi.getAccountingSetupData(getUserId());
        if (response.status === true) {
            docsList.map((docData: any) => {
                if (docData.id === response.data.terms_condition) {
                    setInputData((prevFields) => ({
                        ...prevFields,
                        termsConditionLink: docData.doc,
                        termsConditionDocId: docData.id
                    }))
                    setDocumentName(docData.doc)
                }
            })
            
            setInputData((prevFields) => ({
                ...prevFields,
                giveInvoiceTitle: response.data.invoice_title,
                paymentTerms: response.data.payment_term,
                paymentMethod: response.data.payment_method,
                deliveryTerm: response.data.delivery_type
            }))
            setDocumentName(response.data.doc_name)
        }
      }

    const postSetupStepCount = async () => {
        const payload = {
          setup_count: 4,
          step_count: 3
        };
    
        const response = await HomeApi.postSetupStepCount(payload);
      };

    const nextStep = async () => {
        localStorage.setItem("accountingSetup", "3")

        let userId;
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails) {
            const details = JSON.parse(loginUserDetails)
            userId = details.id
        }
        
        let formData = new FormData();    //formdata object
        formData.append('invoice_title', inputData.giveInvoiceTitle);   //append the values with key, value pair
        formData.append('payment_term', inputData.paymentTerms);
        formData.append('payment_method', inputData.paymentMethod)
        formData.append('terms_condition_id', inputData.termsConditionDocId)
        formData.append('documentValue', documentUpload[0]);
        formData.append('delivery_type', inputData.deliveryTerm)
        formData.append('uploaded_doc_name', documentName)
        formData.append('setup_count', "4")
        formData.append('step_count', "3")
        formData.append('setup_complete_per', "100%")
        
        const config = {     
            headers: { 'content-type': 'multipart/form-data' }
        }
        
        const response = await HomeApi.uploadAccountThirdStepData(formData, config, userId);
        if (response.status === true) {
            Notification.notifySuccess(response.message)
            makeSetupComplete()
            emptyData();
        } else {
            Notification.notifyError(response.message)
        }
    };

    const getTermsAndConditionsData = async () => {
        let userId;
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails) {
            const details = JSON.parse(loginUserDetails)
            userId = details.id
        }
        const response = await HomeApi.getTermsAndConditions(userId);
        
        if (response.status === true) {
            response.doc_name.length > 0 ? setIsDocNameAvailable(true) : setIsDocNameAvailable(false)
            setDocsList(response.doc_name)
            let tempArray: any = [];
            response.doc_name.map((element: any) => {
              tempArray.push(element.doc)
            });
            setDocsNameList(tempArray)
        }
        
    }

    const makeSetupComplete = async () => {
        let userId;
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails) {
            const details = JSON.parse(loginUserDetails)
            userId = details.id
        }

        const payload= {
            isCompleteSetup: "1"
        }
        const response = await HomeApi.isSetUpComplete(payload, userId);
        
        if (response.status === true) {
            navigate("/incomplete-dashboard")
        }
        
    }

    useEffect(() => {
        getTermsAndConditionsData()
    }, [])

    useEffect(() => {
        validateForNextStep()
    }, [inputData.giveInvoiceTitle])


    const validateForNextStep = () => {
    if (inputData.giveInvoiceTitle) {
        setIsNextStepDisable(false)
    } else {
        setIsNextStepDisable(true)
    }
    }

    const emptyData = () => {
        setInputData({
            giveInvoiceTitle: "",
            paymentTerms: "",
            paymentMethod: "",
            termsConditionLink: "",
            termsConditionDocId: "",
            // tcFileUploaded: "",
            deliveryTerm: ""
        })

    }

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    function handleOpen() {
        setIsShowModal(true);
    }

    function handleClose() {
        setIsShowModal(false);
    }

    const dummyView = () => {
        return <Box sx={{ display: "flex", minWidth: 80, height: 18 }} />;
    };

    const dummyView2 = () => {
        return <Box sx={{ display: "flex", minWidth: 80, height: 25 }} />;
    };

    const selectionView = (
        key: any,
        items: any,
        value: any,
        label: any,
        canSelect = true) => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width:{ xs: "110%", sm: "89%", lg: "100%", xl: "100%" }
                    // width: key === 'terms_condition_link' && inputData.termsConditionLink ? "111%" 
                    // : { xs: "110%", sm: "89%", lg: "100%", xl: "100%" }
                }}
            >
                <CustomTitleSelect
                    title={label}
                    value={value}
                    onChange={(event: any) => {
                        if (key === "payment_terms") {
                            setInputData((prevFields) => ({
                                ...prevFields,
                                paymentTerms: event.target.value,
                            }))
                        } else if (key === "payment_method") {
                            setInputData((prevFields) => ({
                                ...prevFields,
                                paymentMethod: event.target.value,
                            }))
                        } else if (key === "terms_condition_link") {
                            const selectedDoc: any = docsNameList.filter((name: any) => (name === event.target.value))
                            docsList.map((docData: any) => {
                                if (docData.doc === selectedDoc[0]) {
                                    setInputData((prevFields) => ({
                                        ...prevFields,
                                        termsConditionLink: docData.doc,
                                        termsConditionDocId: docData.id
                                    }))
                                    setDocumentName(docData.doc)
                                }
                            })
                        } else if (key === "delivery_terms") {
                            setInputData((prevFields) => ({
                                ...prevFields,
                                deliveryTerm: event.target.value,
                            }))
                        }
                    }}
                    items={items}
                    canSelect={canSelect}
                    keepTextGreen={true}
                />
            </Box>
        )
    }

    const accountingInformationView = () => {
        return (
            <Box
                sx={{
                    display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
                    flexDirection: "column",
                    marginTop: '20px',
                    marginLeft: '50px'
                }}
            >
                <StepInformation
                    bgColor={"rgba(15, 92, 208, 0.8)"}
                    minWidth={'100%'}
                    textColor={WHITE}
                    textOne={t("YC_STEP_ACCOUNTING_SETUP_S3_INFO_1")}
                    textTwo={t("YC_STEP_ACCOUNTING_SETUP_S3_INFO_2")}
                />
                <Box sx={{ display: "flex", minWidth: 40, height: 20 }} />
                <StepInformation
                    bgColor={RED}
                    minWidth={'100%'}
                    textColor={WHITE}
                    textOne={t("YC_STEP_ACCOUNTING_SETUP_S3_INFO_RED_1")}
                />
            </Box>
        )
    }

    const renderIcon = (iconName: any) => {
        return (
            <img
                src={iconName}
                height={iconName === ErrorExclamation ? 35 : 22}
                alt={"icon"}
            />
        );
    };

    const textFieldView = (key: string, label: string = "", value: string) => {
        return (
            <Box>
                <FormControl
                    sx={{
                        width: "100%",
                        boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.07)",
                        background: WHITE,
                    }}
                    variant="outlined"
                >
                    <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
                    <OutlinedInput
                        endAdornment={
                            value?.length === 0 ? (<InputAdornment position="end">
                                {renderIcon(ErrorExclamation)}
                            </InputAdornment>) :
                                <InputAdornment position="end">
                                    {renderIcon(SuccessIcon)}
                                </InputAdornment>
                        }
                        label={label}
                        value={value}
                        onChange={(text) => {
                            const trimStr = text.target.value.trimStart();
                            if (key === "give_invoice_title") {
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    giveInvoiceTitle: trimStr,
                                }));
                            } else if (key === "document_name") {
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    documentName: trimStr,
                                }));
                            }
                        }}
                    />
                </FormControl>
            </Box>
        );
    };

    const renderTCDocSelectAndUploadFieldView = () => {
        return(
            <>
                <Box sx={{
                                width: { xs: "100%", sm: "80%", lg: "90%", xl: "90%" },
                                display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
                                flexDirection: "row",
                                borderWidth: 1,
                                borderStyle: 'solid',
                                borderRadius: 2,
                                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
                                border: 1,
                                background: "#F9F9FA"
                            }}
                            >
                                <InputLabel sx={{
                                    width: { xs: "75%", sm: "80%", lg: "80%", xl: "30%" },
                                    textAlign: 'start', paddingTop: '4%', paddingLeft: { xs: "35%", sm: "40%", lg: "2%", xl: "2%" }, fontWeight: "700",
                                    fontSize: 16, color: BLACK
                                }}>{t("YC_TC_UPLOADED")}</InputLabel>
                                <Box sx={{
                                    width: { xs: "50%", sm: "50%", lg: "50%", xl: "70%" },//documentUpload ? { xs: "50%", sm: "50%", lg: "50%", xl: "50%" } : { xs: "35%", sm: "25%", lg: "30%", xl: "25%" },
                                    height: '60px',
                                    borderStyle: 'solid',
                                    borderWidth: '0.1px',
                                    borderRadius: 1,
                                    marginLeft: documentUpload ? { xs: 10.5, sm: 15.5, lg: 15, xl: 10 } : { xs: 13.5, sm: 20, lg: 10, xl: 10 }
                                }}
                                >
                                    <InputLabel sx={{
                                        width: "100%",
                                        textAlign: 'center', paddingTop: '8%', paddingLeft: "2%",
                                        fontSize: 16, color: GREEN
                                    }}>{documentName}</InputLabel>
                                </Box>
                            </Box>
            </>
        )
    }

    return (
        <Box
            sx={{
                m: 3,
                pr: 5,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <Typography className={titleText} sx={{ display: "flex", mb: 5 }}>
                    {t("YC_ACCOUNTING_STEP_TWO_TITLE")}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignContent: "flex-end",
                        width: "60%"
                    }}
                >
                    <CustomButton
                        placeHolder={t("YC_SKIP_STEP_BUTTON")}
                        textTransform="none"
                        background={GREY}
                        height="40px"
                        width={"30%"}
                        borderRadius="7.2px"
                        buttonStyle={{
                            HEADER2,
                            minWidth: "200px",
                        }}
                        onClick={togglePopup}
                    />
                </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" }, width: "100%" }}>
                <Box
                    sx={{
                        display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
                        flexDirection: "column",
                        width: "100%",
                        minWidth: { xs: '100%', sm: '100%', lg: '50%', xl: '50%' }
                    }}
                >
                    <Box sx={{ display: "flex", flexDirection: "column", width: { xs: "100%", sm: "80%", lg: "90%", xl: "90%" } }}>
                        {dummyView()}
                        {textFieldView(
                            "give_invoice_title",
                            t("YC_GIVE_INVOICE_TEMPLATE_TITLE"),
                            inputData.giveInvoiceTitle
                        )}
                    </Box>
                    {dummyView2()}
                    {selectionView("payment_terms", [t("YC_BEFORE_DELIVERY"), t("YC_AFTER_DELIVERY"), t("YC_ON_DELIVERY")], inputData.paymentTerms, t("YC_PAYMENT_TERMS"))}
                    {dummyView2()}
                    {selectionView("payment_method", [t("YC_BANK_TRANSFER"), t("YC_MOBILE_PAY"), t("YC_CREDIT_CARD"), t("YC_CASH")], inputData.paymentMethod, t("YC_PAYMANT_METHOD_AVAILABLE"))}
                    {dummyView2()}
                    {selectionView("terms_condition_link", docsNameList, inputData.termsConditionLink, t("YC_TERMS_CONDITION_LINK"))}
                    {/* {dummyView2()} */}
                    <Box sx={{
                        width: { xs: "100%", sm: "80%", lg: "90%", xl: "90%" },
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end"
                    }}>
                        <Button
                            sx={{
                            textTransform: "none",
                            "&:hover": {
                                backgroundColor: "#fff",
                            },
                            }}
                            variant="text"
                            onClick={handleOpen}
                        >
                            <Link sx={{ mx: 1, color: LINK }} variant="h6" fontSize={16}>
                            {t("YC_UPLOAD_NEW_FILE")}
                            </Link>
                        </Button>
                    </Box>
                    {
                      (documentUpload !== "" || inputData.termsConditionLink) && (renderTCDocSelectAndUploadFieldView())
                    }
                    {(documentUpload !== "" || inputData.termsConditionLink) && (dummyView2())}
                    {selectionView("delivery_terms", [], inputData.deliveryTerm === "" ? "deliverypolicy.pdf" : inputData.deliveryTerm , t("YC_DELIVERY_TERMS"))}
                    {/* <Box sx={{ display: "flex", minWidth: 80, height: 15 }} /> */}
                    <Box sx={{
                        width: { xs: "100%", sm: "80%", lg: "90%", xl: "90%" },
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start"
                    }}>
                        <InputLabel style={{marginTop: 6, color: RED}}>
                        {t("YC_DELIVERY_POLICY_STATEMENT_1")}
                        </InputLabel>
                    </Box>
                    <Box sx={{
                        width: { xs: "100%", sm: "80%", lg: "90%", xl: "90%" },
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start"
                    }}>
                        <InputLabel style={{marginTop: 6, color: RED}}>
                        {t("YC_DELIVERY_POLICY_STATEMENT_2")}
                        </InputLabel>
                        <Button
                            sx={{
                            textTransform: "none",
                            "&:hover": {
                                backgroundColor: "#fff",
                            },
                            }}
                            variant="text"
                            onClick={() => {setIsShowPolicyModal(true)}}
                        >
                            <Link sx={{ mx: 1, color: LINK, marginLeft: -0.5 }} variant="h6" fontSize={16}>
                            {t("YC_CLICK_HERE")}
                            </Link>
                        </Button>
                    </Box>
                </Box>
                {accountingInformationView()}
            </Box>
            <div style={{ marginTop: 10 }} />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: 'row',
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "flex-end",
                    width: "98%",
                    mb: 3,
                    mt: 3
                }}
            >
                <CustomButton
                    placeHolder={`${"<" + " " + t("YC_PREVIOUS_STEP")}`}
                    textTransform="none"
                    background={GREY}
                    height="40px"
                    width={"20%"}
                    borderRadius="7.2px"
                    buttonStyle={{
                        HEADER2,
                        minWidth: "200px",
                    }}
                    onClick={() => {
                        localStorage.setItem("accountingSetup", "2")
                        props.goToNext(2, true)
                    }}
                />
                <CustomButton
                    disabled={isNextStepDisable}
                    placeHolder={t("YC_NEXT_STEP")}
                    textTransform="none"
                    background={isNextStepDisable ? GREY : BLUE}
                    height="40px"
                    width={"20%"}
                    borderRadius="7.2px"
                    buttonStyle={{
                        HEADER2,
                        minWidth: "200px",
                    }}
                    onClick={() => {
                        nextStep()
                    }}
                />
            </Box>
            {/* <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    alignContent: "flex-end",
                    width: "100%",
                    mb: 3,
                    mt: 3
                }}
            >
                <CustomButton
                    disabled={isNextStepDisable}
                    placeHolder={t("YC_NEXT_STEP")}
                    textTransform="none"
                    background={isNextStepDisable ? GREY : BLUE}
                    height="40px"
                    width={"20%"}
                    borderRadius="7.2px"
                    buttonStyle={{
                        HEADER2,
                        minWidth: "200px",
                    }}
                    onClick={() => {
                        nextStep()
                    }}
                />
            </Box> */}
            {isOpen && <PopUpModal
                title={t("YC_SKIP_STEP")}
                description={t("YC_ACCOUNTING_SETUP_POP_UP_TEXT")}
                handleClose={togglePopup}
                firstbuttonName={t("YC_YES")}
                secondbuttonName={t("YC_NO")}
                firstbuttonAction={() => {
                    postSetupStepCount()
                    makeSetupComplete()
                }}
                secondbuttonAction={() => {
                    togglePopup()
                }}
            />}
            <Modal
                open={isShowModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    <NewFileUploadModelView 
                     setDocumentName={setDocumentName}
                     setDocumentUpload={setDocumentUpload}
                     setIsShowModal={setIsShowModal}
                     />
                </div>
            </Modal>
            <Modal
              open={isShowPolicyModal}
              onClose={() => {setIsShowPolicyModal(false)}}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div>
                <PdfView/>
              </div>
            </Modal>
        </Box>
    );
}
export default AccountingProcessThree;
