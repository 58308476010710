import { Box, FormControl, InputLabel, MenuItem, Modal, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckBox from "components/check-box/check-box";
import CustomButton from "components/custom-button/custom-button";
import React, { useEffect } from "react";
import { BLACK, BLUE, DARKGREY, GREY, GREY_3, WHITE } from "utils/colors";
import { SUBHEADER1, SUBHEADERBOLD } from "utils/fonts";
import MultiCheckboxComponent from "./multiple-checkbox-component";
import HomeApi from "services/HomeAPi";
import { useTranslation } from "react-i18next";
import Notification from "views/Notifications/Notification";

const CapacityDialogStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginTop: "10px",
        marginLeft: "20px",
        marginBottom: "10px",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        height: "90%",
        overflowY: 'auto',
        OverflowX: "none",
        background: "white",
    }

}))
function CapacityDialog(props: any) {
    const { boxView } = CapacityDialogStyle();
    const [controllerOfAccount, setControllerOfAccount] = React.useState("");
    const [capacityPermissionList, setCapacityPermissionList] = React.useState([])
    const [capacityPermissionFocusAddedList, setCapacityPermissionFocusAddedList] = React.useState([{
        id: 0,
        focus: "",
        isAdded: false
    }])
    const [permissionFirstColumnList, setPermissionFirstColumnList] = React.useState([{
        id: 0,
        focus: "",
        isAdded: false
    }])
    const [permissionSecondColumnList, setPermissionFirstSecondList] = React.useState([{
        id: 0,
        focus: "",
        isAdded: false
    }])
    const [permissionThirdColumnList, setPermissionThirdColumnList] = React.useState([{
        id: 0,
        focus: "",
        isAdded: false
    }])
    const [selectedPermission, setSelectedPermission] = React.useState([]);
    const [selectedPermissionIdFirstColumn, setSelectedPermissionIdFirstColumn] = React.useState([]);
    const [selectedPermissionIdSecColumn, setSelectedPermissionIdSecColumn] = React.useState([]);
    const [selectedPermissionIdThirdColumn, setSelectedPermissionIdThirdColumn] = React.useState([]);
    const [capacityPermissionNameList, setCapacityPermissionNameList] = React.useState([])
    const [searchCapacityText, setSearchCapacityText] = React.useState("")
    const { t } = useTranslation()
    const [applicationList, setApplicationList] = React.useState([])
    const [applicationNameList, setApplicationNameList] = React.useState([])
    const [selectedApp, setSelectedApp] = React.useState("")
    const [selectedAppID, setSelectedAppID] = React.useState("")
    const [selectedRole, setSelectedRole] = React.useState("")
    const [selectedRoleID, setSelectedRoleID] = React.useState("")
    const [duplicateRoleName, setDuplicateRoleName] = React.useState("")
    const [isDuplicateViewDisplay, setIsDuplicateViewDisplay] = React.useState(false)
    const [isDuplicateSubmitDisable, setIsDuplicateSubmitDisable] = React.useState(true)
    const [isAddRoleDisable, setIsAddRoleDisable] = React.useState(true)
    const [focusArea, setFocusArea] = React.useState([
        { focus: "Activate apps", isAdded: false },
        { focus: "Add appointment", isAdded: false },
        { focus: "Add calendar", isAdded: false },
        { focus: "Add contacts", isAdded: false },
        { focus: "Add emails", isAdded: false },
        { focus: "Add funnels", isAdded: false },
        { focus: "Add pipelines", isAdded: false },
        { focus: "Add replacements", isAdded: false },
        { focus: "Add sms", isAdded: false },
        { focus: "Add tags", isAdded: false },
        { focus: "Answer chat", isAdded: false },
        { focus: "Assign product terms", isAdded: false },
        { focus: "Assign shop coupon terms", isAdded: false },
        { focus: "Assign shop order terms", isAdded: false },
        { focus: "Assign shop webhook terms", isAdded: false },
        { focus: "Assign topic tags", isAdded: false },
        { focus: "Analytics manage settings", isAdded: false },
        { focus: "Analytics view analytics", isAdded: false },
        { focus: "Cancel broadcasts", isAdded: false },
        { focus: "Cancel events", isAdded: false },
        { focus: "Create posts", isAdded: false },
        { focus: "Create sites", isAdded: false },
        { focus: "Create users", isAdded: false },
        { focus: "Delete appointment", isAdded: false },
        { focus: "Delete assignments", isAdded: false },
        { focus: " Delete calendar", isAdded: false },
        { focus: "Delete accounting", isAdded: false },
        { focus: "Delete contacts", isAdded: false },
        { focus: "Delete emails", isAdded: false },
        { focus: "Delete forums", isAdded: false },
        { focus: "Delete funnels", isAdded: false },
        { focus: "Delete item", isAdded: false },
        { focus: "Delete items", isAdded: false },
        { focus: "Delete membership plan", isAdded: false },
        { focus: "Delete newsletters", isAdded: false },
        { focus: "Delete item", isAdded: false },
        { focus: "Delete item", isAdded: false },
    ]);

    useEffect(() => {
        getCapacityPermissionList()
        getApplicationList()
    }, [])

    const handleSubmitClickable = () => {
        if (selectedAppID !== "" && selectedRoleID !== "" && duplicateRoleName !== "" && !isAddRoleDisable) {
            setIsDuplicateSubmitDisable(false)
        } else {
            setIsDuplicateSubmitDisable(true)
        }
    };

    const handleAddRoleClickable = () => {
        if (selectedAppID !== "" && selectedRoleID !== "" && (selectedPermissionIdFirstColumn.length > 0 || selectedPermissionIdSecColumn.length > 0 || selectedPermissionIdThirdColumn.length > 0)) {
            setIsAddRoleDisable(false)
        } else {
            setIsAddRoleDisable(true)
        }
    };

    const getApplicationList = async () => {
        const response = await HomeApi.getApplicationList();
        if (response.status === true) {
            setApplicationList(response.data)
            let tempArray: any = [];
            response.data.map((app: any) => {
            tempArray.push(app.application_name)
            });
            setApplicationNameList(tempArray)
        }
    }

    const getCapacityPermissionList = async () => {
        clearSelectedPermissionId()
        const response = await HomeApi.getPermissionList();
        if (response.status === true) {
            const permissionList = response.data
            setCapacityPermissionList(response.data)
            let tempArray: any = [];
            let tempObjectArray: any = [];
            response.data.map((data: any) => {
            tempArray.push(data.name)
            tempObjectArray.push({
                id: data.id,
                focus:data.name,
                isAdded: false
            })
            });

            let tempArrayFirst: any = [];
            let tempArraySecond: any = [];
            let tempArrayThird: any = [];

            for (let i = 0; i < permissionList.length; i++) {
                if (i < 10) {
                    tempArrayFirst.push({
                        id: permissionList[i].id,
                        focus:permissionList[i].name,
                        isAdded: false
                    })
                } else if (i >= 10 && i < 20) {
                    tempArraySecond.push({
                        id: permissionList[i].id,
                        focus:permissionList[i].name,
                        isAdded: false
                    })
                } else {
                    tempArrayThird.push({
                        id: permissionList[i].id,
                        focus:permissionList[i].name,
                        isAdded: false
                    })
                }
              }
              setPermissionFirstColumnList(tempArrayFirst)
              setPermissionFirstSecondList(tempArraySecond)
              setPermissionThirdColumnList(tempArrayThird)
            setCapacityPermissionFocusAddedList(tempObjectArray)
            setCapacityPermissionNameList(tempArray)
        }
    }

    const getCapacityPermissionListBySearch = async (text: string) => {
        clearSelectedPermissionId()
        const response = await HomeApi.getCapacityBySearch(text);
        if (response.success === true) {
            const permissionList = response.data
            setCapacityPermissionList(response.data)
            let tempArray: any = [];
            let tempObjectArray: any = [];
            response.data.map((data: any) => {
            tempArray.push(data.name)
            tempObjectArray.push({
                id: data.id,
                focus:data.name,
                isAdded: false
            })
            });

            let tempArrayFirst: any = [];
            let tempArraySecond: any = [];
            let tempArrayThird: any = [];

            for (let i = 0; i < permissionList.length; i++) {
                if (i < 10) {
                    tempArrayFirst.push({
                        id: permissionList[i].id,
                        focus:permissionList[i].name,
                        isAdded: false
                    })
                } else if (i >= 10 && i < 20) {
                    tempArraySecond.push({
                        id: permissionList[i].id,
                        focus:permissionList[i].name,
                        isAdded: false
                    })
                } else {
                    tempArrayThird.push({
                        id: permissionList[i].id,
                        focus:permissionList[i].name,
                        isAdded: false
                    })
                }
              }
              setPermissionFirstColumnList(tempArrayFirst)
              setPermissionFirstSecondList(tempArraySecond)
              setPermissionThirdColumnList(tempArrayThird)
            setCapacityPermissionFocusAddedList(tempObjectArray)
            setCapacityPermissionNameList(tempArray)
        }
    }

    const getAssignPermissionByRoleAndApplication = async() => {
        const response = await HomeApi.getAssignPermissionByRoleAndApplication(selectedAppID, selectedRoleID)
        if (response.status === true) {
            const permissionList = response.data
            setCapacityPermissionList(response.data)
            let tempArray: any = [];
            let tempObjectArray: any = [];
            response.data.map((data: any) => {
            tempArray.push(data.name)
            tempObjectArray.push({
                id: data.id,
                focus:data.name,
                isAdded: data.assigned
            })
            });

            let tempArrayFirst: any = [];
            let tempArraySecond: any = [];
            let tempArrayThird: any = [];

            for (let i = 0; i < permissionList.length; i++) {
                if (i < 10) {
                    tempArrayFirst.push({
                        id: permissionList[i].id,
                        focus:permissionList[i].name,
                        isAdded: permissionList[i].assigned === "true" ? true : false
                    })
                } else if (i >= 10 && i < 20) {
                    tempArraySecond.push({
                        id: permissionList[i].id,
                        focus:permissionList[i].name,
                        isAdded: permissionList[i].assigned === "true" ? true : false
                    })
                } else {
                    tempArrayThird.push({
                        id: permissionList[i].id,
                        focus:permissionList[i].name,
                        isAdded: permissionList[i].assigned === "true" ? true : false
                    })
                }
              }
              setPermissionFirstColumnList(tempArrayFirst)
              setPermissionFirstSecondList(tempArraySecond)
              setPermissionThirdColumnList(tempArrayThird)
            // setCapacityPermissionFocusAddedList(tempObjectArray)
            // setCapacityPermissionNameList(tempArray)
        }else {
            Notification.notifyError(response.message)
        }
    }

    useEffect(() => {
        if (selectedAppID !== "" && selectedRoleID !== "") {
            getAssignPermissionByRoleAndApplication()
        }
    }, [selectedAppID, selectedRoleID])

    const clearSelectedPermissionId = () => {
       setSelectedPermissionIdFirstColumn([])
       setSelectedPermissionIdSecColumn([])
       setSelectedPermissionIdThirdColumn([])
    }

    const handleOnChangeFirstColumn = (event: any, option: any, index: any) => {
        // const values = [...capacityPermissionFocusAddedList];
        // values[index].isAdded = event.target.checked;
        // setCapacityPermissionFocusAddedList(values)

        const values = [...permissionFirstColumnList];
        values[index].isAdded = event.target.checked;
        setPermissionFirstColumnList(values)
        setSelectedPermissionDataFirstColumn()
    };

    const handleOnChangeSecondColumn = (event: any, option: any, index: any) => {
        const values = [...permissionSecondColumnList];
        values[index].isAdded = event.target.checked;
        // setFocusArea(values);
        setPermissionFirstSecondList(values)
        setSelectedPermissionDataSecondColumn()
    };

    const handleOnChangeThirdColumn = (event: any, option: any, index: any) => {
        const values = [...permissionThirdColumnList];
        values[index].isAdded = event.target.checked;
        // setFocusArea(values);
        setPermissionThirdColumnList(values)
        setSelectedPermissionDataThirdColumn()
    };
    
//    useEffect(() => {
//     setSelectedPermissionData()
//    }, [capacityPermissionFocusAddedList])

//    const setSelectedPermissionData = () => {
//     let tempArray: any = [];
//     capacityPermissionFocusAddedList.map((data: any) => {
//      if(data.isAdded === true) {
//         tempArray.push(data.id)
//      }
//     });
//     setSelectedPermission(tempArray)
//    }

useEffect(() => {
    setSelectedPermissionDataFirstColumn()
   }, [permissionFirstColumnList])

   const setSelectedPermissionDataFirstColumn = () => {
    let tempArray: any = [];
    permissionFirstColumnList.map((data: any) => {
     if(data.isAdded === true) {
        tempArray.push(data.id)
     }
    });
    setSelectedPermissionIdFirstColumn(tempArray)
    handleAddRoleClickable()
   }

   useEffect(() => {
    setSelectedPermissionDataSecondColumn()
   }, [permissionSecondColumnList])

   const setSelectedPermissionDataSecondColumn = () => {
    let tempArray: any = [];
    permissionSecondColumnList.map((data: any) => {
     if(data.isAdded === true) {
        tempArray.push(data.id)
     }
    });
    setSelectedPermissionIdSecColumn(tempArray)
    handleAddRoleClickable()
   }

   useEffect(() => {
    setSelectedPermissionDataThirdColumn()
   }, [permissionThirdColumnList])

   const setSelectedPermissionDataThirdColumn = () => {
    let tempArray: any = [];
    permissionThirdColumnList.map((data: any) => {
     if(data.isAdded === true) {
        tempArray.push(data.id)
     }
    });
    setSelectedPermissionIdThirdColumn(tempArray)
    handleAddRoleClickable()
   }

   const postcapacityData = async () => {
    const selectedPermissionIds = [...selectedPermissionIdFirstColumn, ...selectedPermissionIdSecColumn, ...selectedPermissionIdThirdColumn]
    const payload = {
        permission : selectedPermissionIds,
        yclickapplication_id : selectedAppID,
        yclickrole_id : selectedRoleID
    } 
    const response = await HomeApi.postPermissionCapacityData(payload)
    if (response.status === true) {
        Notification.notifySuccess(response.message)
      } else {
        Notification.notifyError(response.message)
      }
}
   
   const postDuplicateRoleData = async () => {
    const payload = {
        role_id : selectedRoleID,
        application_id : selectedAppID,
        duplicate_role_name : duplicateRoleName
    }
    const response = await HomeApi.postDuplicateRoleData(payload)
    if (response.status === true) {
        Notification.notifySuccess(response.message)
        setIsDuplicateViewDisplay(false)
      } else {
        Notification.notifyError(response.message)
      }
   }   

   const renderDuplicateRoleView = () => {
      return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            borderRadius: "7.2px",
            marginTop: "10px",
            marginLeft: "20px",
            marginBottom: "10px",
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            height: {xs: "10%", sm: "30%", lg: "30%", xl: "30%"},
            overflowY: 'auto',
            OverflowX: "none",
            maxWidth: 700,
            minWidth: 450,
            background: "white",
        }}>
            <Typography style={{
                fontFamily: 'roboto',
                fontSize: 20,
                fontWeight: 700,
                marginLeft: 20,
                marginTop: 10
            }}>{t("YC_CREATE_DUPLICATE_ROLE")}</Typography>
           <Box sx={{display: "flex",
              flexDirection: "column",
              width: "80%",
              marginLeft: "10%",
              marginTop: 2
           }}>
              <Box sx={{display: "flex",
                flexDirection: "row",
                width: "100%"
              }}>
                   <Typography variant="caption" style={{ fontWeight: "bold",fontSize: 14 , marginRight: "2px", width: "40%" }}>
                       {t("YC_DUPLICATE_ROLE")}:
                    </Typography>
                    <Box sx={{display: "flex", width: "100%"}}>
                        {textFieldView(
                        "duplicate_role",
                        t("YC_DUPLICATE_ROLE_NAME"),
                        duplicateRoleName
                        )}
                    </Box>
                </Box>
                <Box sx={{width: 10, height: 50}}/>
                <Box sx={{display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "flex-end"
                }}>
                     <CustomButton
                                disabled={isDuplicateSubmitDisable}
                                placeHolder={t("YC_SUBMIT")}
                                textTransform="none"
                                background={isDuplicateSubmitDisable ? GREY_3 : BLUE}
                                height="40px"
                                alignItems="center"
                                marginRight="10px"
                                marginLeft="20px"
                                width="auto"
                                borderRadius="5px"
                                onClick={() => postDuplicateRoleData()}
                                buttonStyle={{
                                    SUBHEADER1
                                }}>
                            </CustomButton>
                            <CustomButton
                                placeHolder={t("YC_CANCEL")}
                                textTransform="none"
                                background={GREY_3}
                                height="40px"
                                alignItems="center"
                                marginRight="10px"
                                marginLeft="20px"
                                width="auto"
                                borderRadius="5px"
                                onClick={() => {setIsDuplicateViewDisplay(false)}}
                                buttonStyle={{
                                    SUBHEADER1
                                }}>
                            </CustomButton>
                </Box>

           </Box>
        </Box>
      )
   }

   useEffect(() => {
     handleSubmitClickable()
   }, [duplicateRoleName])

   useEffect(() => {
    handleAddRoleClickable()
   }, [selectedAppID, selectedRoleID, selectedPermissionIdFirstColumn, selectedPermissionIdSecColumn, selectedPermissionIdThirdColumn])

   const textFieldView = (key: string, label: string = "", value: string) => {
    return (
      <Box sx={{ height: "40px", width: "100%"}}>
        <TextField 
            sx={{
                width: "100%",
                height: "100%",
                // boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.07)",
                background: WHITE,
            }}
            label= {label} 
            variant="outlined" 
            size="small" 
            onChange={(text: any) => {
                const trimStr = text.target.value.trimStart();
                if (key === "searchCapacity") {
                  setSearchCapacityText(trimStr)
                  if(trimStr.length > 2) {
                      getCapacityPermissionListBySearch(trimStr)
                  } else if (trimStr.length === 0) {
                      getCapacityPermissionList()
                  }
                } else if (key === "duplicate_role") {
                   setDuplicateRoleName(trimStr)
                   handleSubmitClickable()
                }
            }}
        />
      </Box>
    );
  };

    return (
        <Box className={boxView}>
            <Typography style={{
                fontFamily: 'roboto',
                fontSize: 20,
                fontWeight: 700,
                marginLeft: "20px", marginTop: "20px"
            }} gutterBottom>{t("YC_USER_ROLE_EDITOR")}</Typography>

            <Box sx={{
                borderRadius: "7.2px", margin: 2, padding: 2,
                boxShadow: 2,
                border: 2,
                width: "97%",
                height: "fitContent",
                borderColor: 'white',
                display: "flex",
                flexDirection: "row"
            }}>
                <Box sx={{
                    display: "block",
                    flexDirection: "column",
                    width: "75%"
                }}>


                    <Box sx={{
                        display: "block",
                        flexDirection: "column",
                        paddingBottom: "10px"
                    }}>
                        <Typography variant="caption" >{`${t("YC_SELECT_ROLE_MANAGE_CAPABILITIES") + " "+ ":" + " "}`}
                        </Typography>
                        <Select
                            value={selectedRole}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            onChange={(event: any) => {
                                const selectedRole: any = props.roleNameList.filter((name: any) => (name === event.target.value))
                                props.roleList.map((roleData: any) => {
                                if (roleData.doc === selectedRole[0]){
                                    setSelectedRole(roleData.doc)
                                    setSelectedRoleID(roleData.id)
                                }
                            }
                            )}}
                            size="small"
                            placeholder={t("YC_SELECT_ROLE")}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <>{t("YC_SELECT_ROLE")}</>;
                                }
                                return selected;
                            }}
                        >
                             {props.roleNameList.map((item: any) => {
                                    return <MenuItem value={item}>{item}</MenuItem>;
                            })}
                        </Select>
                        <hr
                            style={{
                                color: BLACK,
                                backgroundColor: BLACK,
                                width: "100%",
                                marginTop: 7,
                                height: 2
                            }}
                        />
                    </Box>

                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}>
                        <CheckBox
                            sx={{ color: BLACK, marginLeft: "5px", "&.Mui-checked": { color: BLACK } }}
                            value="top"
                            label={"Select All"}
                            labelPlacement="end"
                        />
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            paddingBottom: "10px"
                        }}>
                            <Typography variant="caption" style={{ fontWeight: "bold", marginRight: "2px" }}>{t("YC_QUICK_FILTER")}:
                            </Typography>
                            {textFieldView(
                            "searchCapacity",
                            t("YC_SEARCH_CAPACITY"),
                            searchCapacityText
                            )}
                            {/* <Select
                                value={controllerOfAccount}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                onChange={handleChange}
                                size="small"
                                placeholder="Administrator"
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <>Administrator</>;
                                    }
                                    return selected;
                                }}
                            >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select> */}
                        </Box>
                        <Box sx={{
                            display: "block",
                            flexDirection: "column",
                            paddingBottom: "10px"
                        }}>
                            <CustomButton
                                placeHolder={t("YC_GRANTED_ONLY")}
                                textTransform="none"
                                background={BLACK}
                                height="25px"
                                alignItems="center"
                                marginRight="10px"
                                marginLeft="20px"
                                width="auto"
                                borderRadius="0px"
                                // onClick={() => validateEmptyData()}
                                buttonStyle={{
                                    SUBHEADER1
                                }}>
                            </CustomButton>
                            <Select
                                value={selectedApp}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                onChange={(event: any) => {
                                    const selectedApp: any = applicationNameList.filter((name: any) => (name === event.target.value))
                                    applicationList.map((appData: any) => {
                                    if (appData.application_name === selectedApp[0]){
                                        setSelectedApp(appData.application_name)
                                        setSelectedAppID(appData.id)
                                    }}
                                )}}
                                size="small"
                                placeholder={t("YC_SELECT_APPLICATION")}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <>{t("YC_SELECT_APPLICATION")}</>;
                                    }
                                    return selected;
                                }}
                            >
                                {applicationNameList.map((item: any) => {
                                    return <MenuItem value={item}>{item}</MenuItem>;
                                })}
                                {/* <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem> */}
                            </Select>
                        </Box>
                    </Box>
                    <hr
                        style={{
                            color: BLACK,
                            backgroundColor: BLACK,
                            width: "100%",
                            marginTop: 7,
                            height: 2
                        }}
                    />
                    <Box sx={{
                        display: "flex",
                        flexDirection: "coloum",
                        justifyContent: "space-between",
                        // height: "30%",
                        overflowY: "scroll",
                        maxHeight: "700px"
                    }}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                        }}>

                            <div>
                                <MultiCheckboxComponent
                                    list={permissionFirstColumnList}
                                    handleOnChange={handleOnChangeFirstColumn}
                                    // onChange={(e) => handleOnChange(e, item, index)}
                                />
                            </div>

                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                        }}>
                            <div>
                                <MultiCheckboxComponent
                                    list={permissionSecondColumnList}
                                    handleOnChange={handleOnChangeSecondColumn}
                                />
                            </div>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                        }}>
                            <div>
                                <MultiCheckboxComponent
                                    list={permissionThirdColumnList}
                                    handleOnChange={handleOnChangeThirdColumn}
                                />
                            </div>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "30%"
                }}>
                    <CustomButton
                        disabled={isAddRoleDisable}
                        placeHolder={t("YC_UPDATE_ROLE")}
                        textTransform="none"
                        background={isAddRoleDisable ? GREY_3 : BLUE}
                        height="25px"
                        alignItems="center"
                        marginRight="40px"
                        marginLeft="40px"
                        width="auto"
                        borderRadius="2px"
                        buttonStyle={{
                            SUBHEADER1,
                            float: "right"
                            , marginBottom: 3
                        }}
                        onClick={() => {postcapacityData()}}
                    >
                    </CustomButton>
                    <Box sx={{
                        borderRadius: "7.2px", margin: 2, padding: 2,
                        boxShadow: 2,
                        border: 2,
                        width: "97%",
                        // height: "80%",
                        borderColor: 'white',
                        justifyContent: "center"
                    }}>
                        <Typography variant="caption" style={{
                            fontWeight: "bold", alignContent: "center", flexGrow: 1,
                            textAlign: "center", display: "block"
                        }}>{t("YC_ROLE_SETTINGS")}
                        </Typography>

                        <Box sx={{
                            borderRadius: "7.2px", margin: 2, padding: 2,
                            boxShadow: 2,
                            border: 2,
                            width: "90%",
                            display: "block",
                            // height: "auto",
                            backgroundColor: '#F2F2F2',
                            borderColor: '#F2F2F2',
                            justifyContent: 'center',
                            alignContent: "center",
                        }}>
                            <Typography variant="caption" style={{
                                fontWeight: "bold", flexGrow: 1,
                                textAlign: "center", display: "block"
                            }}>{t("YC_ROLE_SHORTCUTS")}
                            </Typography>
                            <hr
                                style={{
                                    color: BLACK,
                                    backgroundColor: BLACK,
                                    width: "100%",
                                    marginTop: 9,
                                    height: 2
                                }}
                            />
                            <CustomButton
                                placeHolder={t("YC_ADD_ROLE")}
                                textTransform="none"
                                background="#C5D4EB"
                                titleColor="black"
                                height="25px"
                                alignItems="center"
                                width="auto"
                                borderRadius="2px"
                                borderColor="black"
                                buttonStyle={{
                                    SUBHEADER1,
                                    flexGrow: 1,
                                    textAlign: "center", display: "block",
                                    marginBottom: 6,
                                }}>
                            </CustomButton>
                            <hr
                                style={{
                                    color: BLACK,
                                    backgroundColor: BLACK,
                                    width: "100%",
                                    marginTop: 11,
                                    height: 2
                                }}
                            />
                            <CustomButton
                                placeHolder={t("YC_DUPLICATE_ROLE")}
                                textTransform="none"
                                background="#C5D4EB"
                                titleColor="black"
                                height="25px"
                                alignItems="center"
                                width="auto"
                                borderRadius="2px"
                                buttonStyle={{
                                    SUBHEADER1,
                                    flexGrow: 1,
                                    textAlign: "center", display: "block"
                                    , marginBottom: 6
                                }}
                                onClick={() => {setIsDuplicateViewDisplay(true)}}
                                >
                            </CustomButton>
                            <hr
                                style={{
                                    color: BLACK,
                                    backgroundColor: BLACK,
                                    width: "100%",
                                    marginTop: 11,
                                    height: 2
                                }}
                            />
                        </Box>

                        <Box sx={{
                            borderRadius: "7.2px", margin: 2, padding: 2,
                            boxShadow: 2,
                            marginTop: "50px",
                            border: 2,
                            width: "90%",
                            // height: "90%",
                            backgroundColor: '#F2F2F2',
                            borderColor: '#F2F2F2',
                            justifyContent: 'center'
                        }}>
                            <Typography variant="caption" style={{
                                fontWeight: "bold", flexGrow: 1,
                                textAlign: "center", display: "block"
                            }}>{t("YC_APPROVE_SETTINGS")}
                            </Typography>
                            <hr
                                style={{
                                    color: BLACK,
                                    backgroundColor: BLACK,
                                    width: "100%",
                                    marginTop: 9,
                                    height: 2
                                }}
                            />

                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingBottom: "10px"
                            }}>
                                <Typography variant="caption" style={{ fontWeight: "bold" }}>{t("YC_SEARCH_USER")}:
                                </Typography>
                                <TextField label="pm@themyp.com" variant="outlined" size="small" />
                            </Box>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingBottom: "10px"
                            }}>
                                <Typography variant="caption" style={{ fontWeight: "bold" }}>{t("YC_USER_NAME")}
                                </Typography>
                                <TextField label="pm@themyp.com" variant="outlined" size="small" />
                            </Box>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                paddingBottom: "10px"
                            }}>
                                <Typography variant="caption" style={{ fontWeight: "bold" }}>{t("YC_APPLY_TO_ROLE")}:
                                </Typography>
                                <TextField label="pm@themyp.com" variant="outlined" size="small" />
                            </Box>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                paddingBottom: "10px"
                            }}>
                                <CustomButton
                                    placeHolder={t("YC_SELECT_RESTRICTION")}
                                    textTransform="none"
                                    background="#fa348a"
                                    height="auto"
                                    alignItems="center"
                                    // marginRight="10px"
                                    // marginLeft="20px"
                                    width="auto"
                                    borderRadius="0px"
                                    // onClick={() => validateEmptyData()}
                                    buttonStyle={{
                                        SUBHEADER1
                                    }}>
                                </CustomButton>
                                <CustomButton
                                    placeHolder={t("YC_CANCEL")}
                                    textTransform="none"
                                    background={DARKGREY}
                                    height="25px"
                                    alignItems="center"
                                    marginRight="10px"
                                    marginLeft="20px"
                                    width="auto"
                                    borderRadius="0px"
                                    // onClick={() => validateEmptyData()}
                                    buttonStyle={{
                                        SUBHEADER1
                                    }}>
                                </CustomButton>
                                <CustomButton
                                    placeHolder={t("YC_UPDATE")}
                                    textTransform="none"
                                    background={BLACK}
                                    height="25px"
                                    alignItems="center"
                                    marginRight="10px"
                                    marginLeft="20px"
                                    width="auto"
                                    borderRadius="0px"
                                    // onClick={() => validateEmptyData()}
                                    buttonStyle={{
                                        SUBHEADER1
                                    }}>
                                </CustomButton>

                            </Box>
                        </Box>
                    </Box>

                </Box>
            </Box>
            <Modal
              open={isDuplicateViewDisplay}
            //   onClose={() => {setIsDuplicateViewDisplay(false)}}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div>
                {renderDuplicateRoleView()}
              </div>
            </Modal>

        </Box>
    );
}

export default CapacityDialog;
