import { Box, FormControl, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "components/custom-button/custom-button";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import HomeApi from "services/HomeAPi";
import { BLUE, GREY_3, WHITE } from "utils/colors";
import { SUBHEADER1, SUBHEADERBOLD, SUBHEADERBOLD2 } from "utils/fonts";
import Notification from "views/Notifications/Notification";

const AddUserDialogStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginTop: "10px",
        marginLeft: "20px",
        marginBottom: "10px",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "90%",
        overflowY: 'auto',
        OverflowX: "none",
        maxWidth: 700,
        minWidth: 450,
        background: "white",
    }

}))
function CapacityOption(props: any) {
    const { boxView } = AddUserDialogStyle();
    const { t } = useTranslation()

    const [selectedApp, setSelectedApp] = React.useState(props.selectedApp);
    const [selectedUser, setSelectedUser] = React.useState(props.selectedUser)
    const [userRoleCapacity, setUserRoleCapacity] = React.useState("")
    const [mainCapacity, setMainCapacity] = React.useState([
        { 
          id: 0,
          focus: "", 
          isAdded: false,
          subCapacity: [{
            id: 0,
            focus: "", 
            isAdded: false,
            childCapacity: [{
                id: 0,
                focus: "", 
                isAdded: false,
            }]
          }]},
    ])
    const [focusArea, setFocusArea] = React.useState([
        { focus: "Business Details", isAdded: false },
        { focus: "Catalogue", isAdded: false },
        { focus: "Coupons", isAdded: false },
        { focus: "Quotation Settings", isAdded: false },
        { focus: "Job Vacancies", isAdded: false },
        { focus: "Your Business Reviews", isAdded: false },
        { focus: "Needs Publishing Approval", isAdded: false }
    ]);
    const [subFocusArea, setSubFocusArea] = React.useState([
        { focus: "Opening Hours", isAdded: false },
        { focus: "Location", isAdded: false },
        { focus: "Payment Types Accepted", isAdded: false },
        { focus: "Business Media", isAdded: false },
        { focus: "Social Networks", isAdded: false },
        { focus: "Brands", isAdded: false },
        { focus: "Branches", isAdded: false }
    ]);
    
    useEffect(() => {
      getCapacityByAppID()
      getCapacityRoleUserById()
    }, [])

    const getCapacityByAppID = async() => {
        const response = await HomeApi.getCapacityByApplicaytionId(props.selectedAppID, props.selectedRoleID)
        if (response.status === true) {
            let tempArray: any = [];
            let tempObjectArray: any = [];
            response.data.mainCategory.map((data: any) => {
                let tempSubArray: any = [];
                if(data.subcategories.length > 0) {
                    data.subcategories.map((subData: any) => {
                        let tempChildArray: any = [];
                        subData.childcategories.map((childData: any) => {
                            tempChildArray.push({
                                id: childData.id,
                                focus:childData.name,
                                isAdded: false
                            })
                        })
                        tempSubArray.push({
                            id: subData.id,
                            focus:subData.name,
                            isAdded: false,
                            childCapacity: tempChildArray
                        })
                    })
                }
                tempObjectArray.push({
                    id: data.id,
                    focus:data.name,
                    isAdded: false,
                    subCapacity: tempSubArray
                })
            })
            setMainCapacity(tempObjectArray)
        }else {
            Notification.notifyError(response.message)
        }
    }
      
    const getCapacityRoleUserById = async() => {
        const response = await HomeApi.getCapacityRoleByUserID(props.selectedUserID)
        if (response.status === true) {
            setUserRoleCapacity(response.data)
        }else {
            Notification.notifyError(response.message)
        }
    }
    
    const textFieldView = (key: string, label: string = "", value: string) => {
        return (
          <Box sx={{width: "100%", height: "40px"}}>
            <FormControl
              sx={{
                mb: 1,
                width: "100%",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.07)",
                background: WHITE,
                height: "40px"
              }}
              variant="outlined"
            >
              <OutlinedInput
              sx={{width: '100%', height: "40px"}}
                label={label}
                value={value}
                disabled={true}
                // onChange={}
           />
         </FormControl>
          </Box>
        );
      };

    return (
        <Box className={boxView}>
            <Box sx={{
                borderRadius: "7.2px", margin: 2, padding: 2,
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.light',
                width: "95%"
            }}>
                <Box sx={{
                    display: "block",
                    flexDirection: "column",
                    paddingBottom: "10px"
                }}>
                    <Typography style={SUBHEADERBOLD} gutterBottom>{t("YC_APP_CAPACITY")}:</Typography>
                </Box>

                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                    <Typography variant="caption" style={SUBHEADERBOLD2}> {t("YC_SELECT_APPLICATION")} : </Typography>
                    <Box sx={{ marginLeft: "1%", height: 40, boxShadow: 4, width: "70%", borderRadius: 3}}>
                    {textFieldView(
                                "selectApplication",
                                "",
                                selectedApp
                            )}
                        {/* <Select
                            sx={{ height: 40, width: "100%", borderRadius: 3 }}
                            value={selectedApp}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            onChange={(event: any) => {}}
                            placeholder={t("YC_ADMINISTRATOR")}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <>{t("YC_ADMINISTRATOR")}</>;
                                }
                                return selected;
                            }}
                        > */}
                            {/* {props.roleNameList.map((item: any) => {
                            return (
                                <MenuItem
                                value={item}
                                >
                                {item}
                                </MenuItem>
                            );
                            })} */}
                        {/* </Select> */}
                    </Box>
                </Box>

                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px"

                }}>
                    <Typography variant="caption" style={SUBHEADERBOLD2}> {t("YC_SELECT_USER")} :</Typography>
                    <Box sx={{ marginLeft: "1%", height: 40, boxShadow: 4, width: "70%", borderRadius: 3}}>
                            {textFieldView(
                                "selectUser",
                                "",
                                userRoleCapacity
                            )}
                        {/* <Select
                            sx={{ height: 40, width: "100%", borderRadius: 3 }}
                            value={selectedUser}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            onChange={(event: any) => { }}
                            placeholder={t("YC_ADMINISTRATOR")}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <>{t("YC_ADMINISTRATOR")}</>;
                                }
                                return selected;
                            }}
                        > */}
                            {/* {props.roleNameList.map((item: any) => {
                            return (
                                <MenuItem
                                value={item}
                                >
                                {item}
                                </MenuItem>
                            );
                            })} */}
                        {/* </Select> */}
                    </Box>
                </Box>
                <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                }}>

                    <div style={{ width: "80%"}}>
                    {mainCapacity?.map((item: any, index: any) => (
                        <div>
                            <input
                                style={{ fontStyle: "normal", width: 20, height: 20}}
                                type="checkbox"
                                id={item.id}
                                value={item.focus}
                                checked={item.isAdded}
                                onChange={(e) => {}}
                            />
                            <label style={{marginLeft: 5, fontSize: 14}}>
                                {item.focus}
                            </label>
                            {item.subCapacity.length > 0 && (
                                item.subCapacity?.map((item: any, index: any) => (
                                    <div style={{marginLeft: "10%"}}>
                                        <input
                                            style={{ fontStyle: "normal", width: 20, height: 20}}
                                            type="checkbox"
                                            id={item.id}
                                            value={item.focus}
                                            checked={item.isAdded}
                                            onChange={(e) => {}}
                                        />
                                        <label style={{marginLeft: 5, fontSize: 14}}>
                                            {item.focus}
                                        </label>
                                        {item.childCapacity.length > 0 && (
                                            item.childCapacity?.map((item: any, index: any) => (
                                                <div style={{marginLeft: "10%"}}>
                                                    <input
                                                        style={{ fontStyle: "normal", width: 20, height: 20}}
                                                        type="checkbox"
                                                        id={item.id}
                                                        value={item.focus}
                                                        checked={item.isAdded}
                                                        onChange={(e) => {}}
                                                    />
                                                    <label style={{marginLeft: 5, fontSize: 14}}>
                                                        {item.focus}
                                                    </label>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                ))
                            )}
                        </div>
                    ))}
                    </div>
                </Box>
                <Box sx={{ float: "right", display: "flex", flexDirection: "row" }}>
                    <CustomButton
                        placeHolder={t("YC_OK")}
                        textTransform="none"
                        background={BLUE}
                        height="40px"
                        alignItems="center"
                        marginRight="10px"
                        marginLeft="20px"
                        width="auto"
                        borderRadius="4px"
                        onClick={() => {}}
                        buttonStyle={{
                            SUBHEADER1
                        }}>
                    </CustomButton>
                    <CustomButton
                        placeHolder={t("YC_CANCEL")}
                        textTransform="none"
                        background={GREY_3}
                        height="40px"
                        alignItems="center"
                        marginRight="10px"
                        marginLeft="20px"
                        width="auto"
                        borderRadius="4px"
                        onClick={() => props.setIsShowModal(false)}
                        buttonStyle={{
                            SUBHEADER1
                        }}>
                    </CustomButton>
                </Box>
            </Box>
        </Box>
    );
}

export default CapacityOption;
