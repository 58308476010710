import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import CustomButton from "components/custom-button/custom-button";
import CustomSelect from "components/custom-select/custom-select";
import { BLACK, BLUE, GREY, RED, WHITE } from "utils/colors";
import StepInformation from "../dashboard-reusables/step-information";
import { HEADER2 } from "utils/fonts";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import SuccessIcon from "../../../assets/success.png";
import ErrorExclamation from "../../../assets/exclamation-mark.png";
import CustomTitleSelect from "components/custom-title-select/custom-title-select";
import HomeApi from "services/HomeAPi";
import Notification from "views/Notifications/Notification";
import PopUpModal from "components/pop-up-view/popup-modal";
import { validateEmail, validateFaxNumber, validateMobilePhone } from "utils/helper";
import { useTranslation } from "react-i18next";
// import { UC_CONTACT_EMAIL, UC_FIX_LINE_NUMBER, YC_COUNTRY_CODE, 
//   YC_EMAIL_VALID, 
//   YC_LANDLINE_VALID, 
//   YC_MOBILE_NUMBER, 
//   YC_NEXT_STEP, YC_NO, YC_PHONE_VALID, YC_POPUP_CONTENT, YC_SKIP_STEP, YC_SKIP_STEP_BUTTON, 
//   YC_STEP_YP_SETUP_S2_INFO_1, YC_STEP_YP_SETUP_S2_INFO_2, YC_STEP_YP_SETUP_S2_INFO_3, 
//   YC_YELLOW_PAGE_STEP_TWO_TITLE, YC_YES } from "utils/strings";

const useStyles = makeStyles(() => ({
  titleText: {
    color: BLACK,
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 1,
    width: '50%'
  },
}));

function YellowPageProcessTwo(props: any) {
  const { titleText } = useStyles();
  const [isOpen, setIsOpen] = React.useState(false)

  const [isNextStepDisable, setIsNextStepDisable] = React.useState(true)
  const [isValidFixLineNumber1, setIsValidFixLineNumber1] = React.useState({ value: true, error: "" })
  const [isValidFixLineNumber2, setIsValidFixLineNumber2] = React.useState({ value: true, error: "" })
  const [isValidMobileNumber, setIsValidMobileNumber] = React.useState({ value: true, error: "" })
  const [isValidFaxNumber, setIsValidFaxNumber] = React.useState({ value: true, error: "" })
  const [isValidEmail, setIsValidEmail] = React.useState({ value: true, error: "" })

  const [inputData, setInputData] = React.useState({
    countryCode: "",
    fixlinenumber_1: "",
    fixLineNumber_2: "",
    mobile: "",
    email: "",
  });

  const { t } = useTranslation()

  useEffect(() => {
    getYellowPageData()
  }, [])

  useEffect(() => {
    validateForNextStep()
  }, [inputData.countryCode, inputData.mobile, inputData.fixlinenumber_1, inputData.fixLineNumber_2, isValidMobileNumber, isValidFixLineNumber1, isValidFixLineNumber2, isNextStepDisable])

  const validateForNextStep = () => {
    if (inputData.countryCode) {
      if ((inputData.mobile && isValidMobileNumber.value)
        || (inputData.fixlinenumber_1 && isValidFixLineNumber1.value)
        || (inputData.fixLineNumber_2 && isValidFixLineNumber2.value)) {
        setIsNextStepDisable(false)
      } else {
        setIsNextStepDisable(true)
      }
    } else {
      setIsNextStepDisable(true)
    }
  }

  const postSetupStepCount = async () => {
    const payload = {
      setup_count: 3,
      step_count: 3
    };

    const response = await HomeApi.postSetupStepCount(payload);
  };

  const nextStep = async () => {
    localStorage.setItem("yellowPageStep", "3")

    let userId;
    const loginUserDetails = localStorage.getItem("user_Details")
    if (loginUserDetails) {
      const details = JSON.parse(loginUserDetails)
      userId = details.id
    }

    const payload = {
      country_code: inputData.countryCode,
      fax_phone_number: inputData.fixlinenumber_1,
      phone: inputData.fixLineNumber_2,
      mobile: inputData.mobile,
      email: inputData.email,
      setup_count: 3,
      step_count: 3,
      setup_complete_per: "66.66%"
    };

    const response = await HomeApi.uploadYellowPageSecondStepData(payload, userId);
    if (response.status === true) {
      Notification.notifySuccess(response.message)
      props.goToNext(3);
      emptyData();
    } else {
      Notification.notifyError(response.message)
    }
  };


  const getUserId = () => {
    let userId;
    const loginUserDetails = localStorage.getItem("user_Details")
    if (loginUserDetails){
      const details = JSON.parse(loginUserDetails)
      userId = details.id
    }
    return userId
  }

  const getYellowPageData = async () => {
    const response = await HomeApi.getYellowPageSetupData(getUserId());
    if (response.status === true) {
      setInputData({
        countryCode: response.data.country_code,
        fixlinenumber_1: response.data.fax_phone_number,
        fixLineNumber_2: response.data.phone,
        mobile: response.data.mobile,
        email: response.data.email,
      })
    }
  }

  const emptyData = () => {
    setInputData({
      countryCode: "",
      fixlinenumber_1: "",
      fixLineNumber_2: "",
      mobile: "",
      email: "",
    })

  }

  const togglePopup = () => {
    setIsOpen(!isOpen);
  }

  const dummyView = () => {
    return <Box sx={{ display: "flex", minWidth: 80, height: 10 }} />;
  };

  const dummyViewForRow = () => {
    return <Box sx={{ display: "flex", minWidth: 30, height: 5 }} />;
  };

  const yellowPageInfoView = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: '8px',
          marginLeft: '50px'
        }}
      >
        <StepInformation
          bgColor={"rgba(15, 92, 208, 0.8)"}
          minWidth={'100%'}
          textColor={WHITE}
          textOne={t("YC_STEP_YP_SETUP_S2_INFO_1")}
          textTwo={t("YC_STEP_YP_SETUP_S2_INFO_2")}
          textThree={t("YC_STEP_YP_SETUP_S2_INFO_3")}
        />
        <Box sx={{ display: "flex", minWidth: 40, height: 20 }} />
      </Box>
    )
  }

  const renderIcon = (iconName: any) => {
    return (
      <img
        src={iconName}
        height={iconName === ErrorExclamation ? 35 : 22}
        alt={"icon"}
      />
    );
  };

  const textFieldView = (key: string, label: string = "", value: string) => {
    return (
      <Box sx={{
        width: key === "country_code" || key === "fixlinenumber_1" || key === "fixLineNumber_2" || key === "mobile" ?
          { xs: "100%", sm: "100%", lg: "42.5%", xl: "42.5%" }
          : { xs: "100%", sm: "80%", lg: "90%", xl: "90%" }
      }}>
        <FormControl
          sx={{
            mb: 1,
            width: "100%",
            boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.07)",
            background: WHITE,
          }}
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
          <OutlinedInput
            endAdornment={
              value?.length === 0 ? (<InputAdornment position="end">
                {renderIcon(ErrorExclamation)}
              </InputAdornment>) :
                ((isValidFixLineNumber1.error && key === "fixlinenumber_1") ||
                  (isValidFixLineNumber2.error && key === "fixLineNumber_2") ||
                  (isValidMobileNumber.error && key === "mobile") ||
                  (isValidFaxNumber.error && key === "fax") ||
                  (isValidEmail.error && key === "email")) ?
                  <InputAdornment position="end">
                    {renderIcon(ErrorExclamation)}
                  </InputAdornment> :
                  <InputAdornment position="end">
                    {renderIcon(SuccessIcon)}
                  </InputAdornment>
            }
            label={label}
            value={value}
            disabled={false}
            onChange={(text) => {
              const trimStr = text.target.value.trimStart();
              if (key === "country_code") {
                setInputData((prevFields) => ({
                  ...prevFields,
                  countryCode: trimStr,
                }));
              } else if (key === "fixlinenumber_1") {
                const fax = text.target.value.replace(/(\d{3})(\d{2})(\d{2})/, "$1 $2 $3");
                const isValidNumber = validateFaxNumber(fax);
                setIsValidFixLineNumber1({ value: isValidNumber, error: !isValidNumber ? t("YC_LANDLINE_VALID") : "" })
                setInputData((prevFields) => ({
                  ...prevFields,
                  fixlinenumber_1: trimStr,
                }));
              } else if (key === "fixLineNumber_2") {
                const fax = text.target.value.replace(/(\d{3})(\d{2})(\d{2})/, "$1 $2 $3");
                const isValidNumber = validateFaxNumber(fax);
                setIsValidFixLineNumber2({ value: isValidNumber, error: !isValidNumber ? t("YC_LANDLINE_VALID") : "" })
                setInputData((prevFields) => ({
                  ...prevFields,
                  fixLineNumber_2: trimStr,
                }));
              } else if (key === "mobile") {
                const mob = text.target.value.replace(/(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4");
                const isValidNumber = validateMobilePhone(mob);
                setIsValidMobileNumber({ value: isValidNumber, error: !isValidNumber ? t("YC_PHONE_VALID") : "" })
                setInputData((prevFields) => ({
                  ...prevFields,
                  mobile: trimStr,
                }));
              } else if (key === "email") {
                const validEmail = validateEmail(value);
                setIsValidEmail({ value: validEmail, error: !validEmail ? t("YC_EMAIL_VALID") : "" })
                setInputData((prevFields) => ({
                  ...prevFields,
                  email: trimStr,
                }));
              }
            }}
            error={(!!isValidFixLineNumber1.error && key === "fixlinenumber_1") ||
              (!!isValidFixLineNumber2.error && key === "fixLineNumber_2") ||
              (!!isValidMobileNumber.error && key === "mobile") ||
              (!!isValidFaxNumber.error && key === "fax") ||
              (!!isValidEmail.error && key === "email")}
          />
          {!!isValidFixLineNumber1.error && key === "fixlinenumber_1" && (
            <FormHelperText error id="validNumber-error">
              {isValidFixLineNumber1.error}
            </FormHelperText>
          )}
          {!!isValidFixLineNumber2.error && key === "fixLineNumber_2" && (
            <FormHelperText error id="validNumber-error">
              {isValidFixLineNumber2.error}
            </FormHelperText>
          )}
          {!!isValidMobileNumber.error && key === "mobile" && (
            <FormHelperText error id="validMobileNumber-error">
              {isValidMobileNumber.error}
            </FormHelperText>
          )}
          {!!isValidFaxNumber.error && key === "fax" && (
            <FormHelperText error id="validFax-error">
              {isValidFaxNumber.error}
            </FormHelperText>
          )}
          {!!isValidEmail.error && key === "email" && (
            <FormHelperText error id="validEmail-error">
              {isValidEmail.error}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        m: 3,
        pr: 5,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <Typography className={titleText} sx={{ display: "flex", mb: 5 }}>
          {t("YC_YELLOW_PAGE_STEP_TWO_TITLE")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "flex-end",
            width: "60%"
          }}
        >
          <CustomButton
            placeHolder={t("YC_SKIP_STEP_BUTTON")}
            textTransform="none"
            background={GREY}
            height="40px"
            width={"30%"}
            borderRadius="7.2px"
            buttonStyle={{
              HEADER2,
              minWidth: "200px",
            }}
            onClick={togglePopup}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <Box
          sx={{
            display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
            flexDirection: "column",
            width: "100%",
            minWidth: { xs: "100%", sm: "0%", lg: "0%", xl: "0%" }
          }}
        >
          <Box sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'column0', lg: 'row', xl: 'row' }, width: "100%" }}>
            {textFieldView(
              "country_code",
              t("YC_COUNTRY_CODE"),
              inputData.countryCode
            )}
            {dummyViewForRow()}
            {textFieldView(
              "fixlinenumber_1",
              t("YC_FAX_PHONE_NUMBER"),
              inputData.fixlinenumber_1
            )}
          </Box>
          {dummyView()}
          <Box sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'column0', lg: 'row', xl: 'row' }, width: "100%" }}>
            {textFieldView(
              "fixLineNumber_2",
              t("YC_PHONE"),
              inputData.fixLineNumber_2
            )}
            {dummyViewForRow()}
            {textFieldView(
              "mobile",
              t("YC_MOBILE_NUMBER"),
              inputData.mobile
            )}
          </Box>
          {dummyView()}
          {textFieldView(
            "email",
            t("UC_CONTACT_EMAIL"),
            inputData.email
          )}
          {dummyView()}
        </Box>
        {dummyView()}
        {yellowPageInfoView()}
      </Box>
      <div style={{ marginTop: 10 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: 'row',
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "flex-end",
          width: "98%",
          mb: 3,
          mt: 3
        }}
      >
        <CustomButton
          placeHolder={`${"<" + " " + t("YC_PREVIOUS_STEP")}`}
          textTransform="none"
          background={GREY}
          height="40px"
          width={"20%"}
          borderRadius="7.2px"
          buttonStyle={{
            HEADER2,
            minWidth: "200px",
          }}
          onClick={() => {
            localStorage.setItem("yellowPageStep", "1")
            props.goToNext(1, true)
          }}
        />
        <CustomButton
          disabled={isNextStepDisable}
          placeHolder={t("YC_NEXT_STEP")}
          textTransform="none"
          background={isNextStepDisable ? GREY : BLUE}
          height="40px"
          width={"20%"}
          borderRadius="7.2px"
          buttonStyle={{
            HEADER2,
            minWidth: "200px",
          }}
          onClick={() => {
            nextStep()
          }}
        />
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          alignContent: "flex-end",
          width: "100%",
          mb: 3,
          mt: 3
        }}
      >
        <CustomButton
          disabled={isNextStepDisable}
          placeHolder={t("YC_NEXT_STEP")}
          textTransform="none"
          background={isNextStepDisable ? GREY : BLUE}
          height="40px"
          width={"20%"}
          borderRadius="7.2px"
          buttonStyle={{
            HEADER2,
            minWidth: "200px",
          }}
          onClick={() => {
            nextStep()
          }}
        />
      </Box> */}
      {isOpen && <PopUpModal
        title={t("YC_SKIP_STEP")}
        description={t("YC_POPUP_CONTENT")}
        handleClose={togglePopup}
        firstbuttonName={t("YC_YES")}
        secondbuttonName={t("YC_NO")}
        firstbuttonAction={() => {
          postSetupStepCount()
          localStorage.setItem("yellowPageStep", "3")
          props.goToNext(3);
        }}
        secondbuttonAction={() => {
          togglePopup()
        }}
      />}
    </Box>
  );
}
export default YellowPageProcessTwo;
