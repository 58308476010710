import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import CustomButton from "components/custom-button/custom-button";
import CustomSelect from "components/custom-select/custom-select";
import { BLACK, BLUE, GREY, RED, WHITE } from "utils/colors";
import StepInformation from "../dashboard-reusables/step-information";
import { HEADER2 } from "utils/fonts";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import SuccessIcon from "../../../assets/success.png";
import CustomTitleSelect from "components/custom-title-select/custom-title-select";
import HomeApi from "services/HomeAPi";
import Notification from "views/Notifications/Notification";
import ErrorExclamation from "../../../assets/exclamation-mark.png";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  titleText: {
    color: BLACK,
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 1,
  },
}));

function BusinessProcessOne(props: any) {
  const { titleText } = useStyles();
  const [inputData, setInputData] = React.useState({
    businessCode: "",
    companyName: "",
    tradeName: "",
    legalStatus: "",
    registrationNumber: "",
    vatRegistered: "",
    vatNumber: "",
    model: "",
    modelID: 0
  });
  const [isVatRegistered, setIsVatRegistered] = React.useState("");
  const [isNextStepDisable, setIsNextStepDisable] = React.useState(true)

  const [businessModelList, setBusinessModelList] = React.useState([])
  const [businessModelNameList, setBusinessModelNameList] = React.useState([])
  const [legalStatusList, setLegalStatusList] = React.useState([])
  const [legalStatusNameList, setLegalStatusNameList] = React.useState([])
  const [legalStatusId, setLegalStatusId] = React.useState(0)
  const { t } = useTranslation()

  useEffect(() => {
    getBusinessSetupData()
    getBusinessModelData()
    getLegalStatusList()
  }, [])

  useEffect(() => {
    validateForNextStep()
  }, [inputData.companyName, inputData.tradeName, inputData.legalStatus, inputData.registrationNumber, inputData.model])

const validateForNextStep = () => {
if (inputData.companyName && inputData.tradeName && inputData.legalStatus && inputData.registrationNumber && inputData.model) {
    setIsNextStepDisable(false)
} else {
    setIsNextStepDisable(true)
}
}

const getUserId = () => {
  let userId;
  const loginUserDetails = localStorage.getItem("user_Details")
  if (loginUserDetails){
    const details = JSON.parse(loginUserDetails)
    userId = details.id
  }
  return userId
}

const nextStep = async (companyDetails: any) => {
  localStorage.setItem("businessStep", "2")
  let userId;
  const loginUserDetails = localStorage.getItem("user_Details")
  if (loginUserDetails){
    const details = JSON.parse(loginUserDetails)
    userId = details.id
  }

  const payload = {
    business_code: companyDetails.businessCode,
    companyname: companyDetails.companyName,
    tradename: companyDetails.tradeName,
    legalstatus: legalStatusId,
    registrationnumber: companyDetails.registrationNumber,
    business_model: companyDetails.modelID,
    setup_count: 2,
    step_count: 2,
    setup_complete_per: "31.25%"
  };

  const response = await HomeApi.postBusinessSetupStepOneDetails(payload, getUserId());

  if (response.status === true) {
    Notification.notifySuccess(response.message)
    emptyData();
    props.isFromDashBoard ?  props.setIsShowBusinessStepOneModal(false) : props.goToNext(2)
    // props.goToNext(2);
  } else {
    Notification.notifyError(response.message)
  }
}; 



const emptyData = () => {
  setInputData({
    businessCode: "",
    companyName: "",
    tradeName: "",
    legalStatus: "",
    registrationNumber: "",
    vatRegistered: "",
    vatNumber: "",
    model: "",
    modelID: 0
  })
  
}

const getBusinessModelData = async () => {
  const response = await HomeApi.getBusinessModuleData();
  if (response.status === true) {
      setBusinessModelList(response.data)
      let tempArray: any = [];
      response.data.map((element: any) => {
        if (Number(inputData.modelID) === element.id){
            setInputData((prevFields) => ({
              ...prevFields,
              model: element.module_name,
            }))
        }
          tempArray.push(element.module_name)
      });
      setBusinessModelNameList(tempArray)
  }
}

useEffect(() => {
  legalStatusList.map((element: any) => {
    if (Number(legalStatusId) === element.id){
        setInputData((prevFields) => ({
          ...prevFields,
          legalStatus: element.legalstatus_name,
        }))
    }})
}, [legalStatusId, inputData.legalStatus])

useEffect(() => {
  businessModelList.map((element: any) => {
    if (Number(inputData.modelID) === element.id){
        setInputData((prevFields) => ({
          ...prevFields,
          model: element.module_name,
        }))
    }})
}, [inputData.modelID, inputData.model])

const getLegalStatusList = async () => {
  const response = await HomeApi.getLegalStatusList();
  if (response.status === true) {
      setLegalStatusList(response.data)
      let tempArray: any = [];
      response.data.map((legalStatusData: any) => {
      tempArray.push(legalStatusData.legalstatus_name)
      });
      setLegalStatusNameList(tempArray)
  }
}

const getBusinessSetupData = async () => {
  const response = await HomeApi.getBusinessSetupData(getUserId())
  if(response.status === true){
     setLegalStatusId(response.data.legalstatus)
     legalStatusList.map((element: any) => {
      if (Number(response.data.legalstatus) === element.id){
          setInputData((prevFields) => ({
            ...prevFields,
            legalStatus: element.legalstatus_name,
          }))
      }})

      businessModelList.map((element: any) => {
        if (Number(response.data.business_model) === element.id){
            setInputData((prevFields) => ({
              ...prevFields,
              model: element.module_name,
            }))
        }})

     setInputData({
      businessCode: response.data.business_code,
      companyName: response.data.companyname,
      tradeName: response.data.tradename,
      legalStatus: response.data.legalstatus,
      registrationNumber: response.data.registrationnumber,
      vatRegistered: response.data.vatregisterd,
      vatNumber: response.data.vatregistrationnumber,
      model: inputData.model,
      modelID: response.data.business_model
     })
  }
}


  const renderIcon = (iconName: any) => {
    return (
        <img
          src={iconName}
          height={iconName === ErrorExclamation ? 35 : 22}
          alt={"icon"}
        />
    );
  };

  const textFieldView = (key: string, label: string = "", value: string) => {
    return (
      <Box sx={{width: { xs: "100%", sm: "80%", lg: "80%", xl: "80%" }}}>
        <FormControl
          sx={{
            mb: 1,
            width: "100%",
            boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.07)",
            background: WHITE,
          }}
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
          <OutlinedInput
            endAdornment={
              value?.length === 0 ?  (<InputAdornment position="end">
              {renderIcon(ErrorExclamation)}
            </InputAdornment>) : (<InputAdornment position="end">
              {renderIcon(SuccessIcon)}
            </InputAdornment>)
            }
            label={label}
            value={value}
            disabled={false}
            onChange={(text) => {
              const trimStr = text.target.value.trimStart();
              if (key === "business_code") {
                setInputData((prevFields) => ({
                  ...prevFields,
                  businessCode: trimStr,
                }));
              } else if (key === "company_name") {
                setInputData((prevFields) => ({
                  ...prevFields,
                  companyName: trimStr,
                }));
              } else if (key === "trade_name") {
                setInputData((prevFields) => ({
                  ...prevFields,
                  tradeName: trimStr,
                }));
              } else if (key === "registration_number") {
                setInputData((prevFields) => ({
                  ...prevFields,
                  registrationNumber: trimStr,
                }));
              } else if (key === "vat_number") {
                setInputData((prevFields) => ({
                  ...prevFields,
                  vatNumber: trimStr,
                }));
              }
            }}
          />
        </FormControl>
      </Box>
    );
  };

  const dummyView = () => {
    return <Box sx={{ display: "flex", minWidth: 80, height: 10 }} />;
  };

  const selectionView = (
    key: any,
    text: any,
    items: any,
    value: any,
    canSelect = true
  ) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: { xs: "110%", sm: "89%", lg: "89%", xl: "89%"}
        }}
      >
        <CustomTitleSelect
          title={text}
          value={value}
          onChange={(event: any) => {
            if (key === "legal_status") {
              const selectedLegalStatus: any = legalStatusNameList.filter((name: any) => (name === event.target.value))
              legalStatusList.map((legalStatusData: any) => {
                if (legalStatusData.legalstatus_name === selectedLegalStatus[0]) {
                  setLegalStatusId(legalStatusData.id)
                }
              })
              setInputData((prevFields) => ({
                ...prevFields,
                legalStatus: event.target.value,
              }))
            } else if (key === "vat_registered") {
              setIsVatRegistered(event.target.value)
              setInputData((prevFields) => ({
                ...prevFields,
                vatRegistered: event.target.value.toLowerCase(),
              }))
            } else if (key === "model") {
              const selectedModel: any = businessModelNameList.filter((name: any) => (name === event.target.value))
              businessModelList.map((model: any) => {
                if (model.module_name === selectedModel[0]) {
                  setInputData((prevFields) => ({
                    ...prevFields,
                    model: event.target.value,
                    modelID: model.id
                }))
                }
              })
              // props.setBusinessModel(event.target.value)
          } 
          }}
          items={items}
          canSelect={canSelect}
          keepTextGreen={true}
        />
      </Box>
    );
  };

  const informationView = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", pr: 3, alignItems: 'flex-end' }}>
        <StepInformation
          bgColor={"rgba(15, 92, 208, 0.8)"}
          minWidth={'100%'}
          textColor={WHITE}
          textOne={t("YC_BUSINESS_SETUP_STEP_ONE_INFO_1")}
        />
        <Box sx={{ display: "flex", minWidth: 40, height: 20 }} />
        <StepInformation
          bgColor={RED}
          minWidth={'100%'}
          textColor={WHITE}
          textOne={t("YC_BUSINESS_SETUP_STEP_ONE_INFO_RED_1")}
        />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        m: 3,
        pr: 5,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}
    >
      <Typography className={titleText} sx={{ display: "flex", mb: 5 }}>
        {t("YC_BUSINESS_SETUP_STEP_ONE_TITLE")}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <Box
          sx={{
            display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
            flexDirection: "column",
            width: "100%",
            minWidth: { xs: "100%", sm: "50%", lg: "50%", xl: "50%" }
          }}
        >
          {textFieldView(
            "business_code",
            t("YC_BUSINESS_CODE"),
            inputData.businessCode
          )}
          {dummyView()}
          {textFieldView(
            "company_name",
            t("YC_BUSINESS_NAME"),
            inputData.companyName
          )}
          {dummyView()}
          {textFieldView(
            "trade_name",
            t("YC_TRADE_NAME"),
            inputData.tradeName
          )}
          {/* <Box sx={{width: { xs: "100%", sm: "80%", lg: "80%", xl: "80%" }}}>
            <CustomSelect
              label={inputData.legalStatus ? YC_LEGAL_STATUS_QUESTION : `${YC_LEGAL_STATUS_QUESTION + " " + '*'}`}
              minWidth={"100%"}
              items={["business", "government", "ngo"]}
              value={inputData.legalStatus}
              isShowTick={false}
              onChange={(event: any) => {
                setInputData((prevFields) => ({
                  ...prevFields,
                  legalStatus: event.target.value,
                }));
              }}
            />
          </Box> */}
          {selectionView(
            "legal_status",
            t("YC_LEGAL_STATUS_QUESTION"),
            legalStatusNameList,
            inputData.legalStatus
          )}
          <Box sx={{ display: "flex", minWidth: 80, height: 15 }} />
          {textFieldView(
            "registration_number",
            t("YC_BRN"),
            inputData.registrationNumber
          )}
          {dummyView()}
          {selectionView("model", t("YC_BUSINESS_MODULE_QUESTION_BUSINESS_SETUP"), businessModelNameList, inputData.model, true)}
        </Box>
        {informationView()}
      </Box>
      <div style={{marginTop: 10}}/>
      <Box
        sx={{
          display: "flex",
          flexDirection: 'row',
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "flex-end",
          width: "98%",
          mb: 3,
          mt: 3
        }}
      >
        {!props.isFromDashBoard && (<CustomButton
          placeHolder={`${"<" + " " +t("YC_PREVIOUS_STEP")}`}
          textTransform="none"
          background={GREY}
          height="40px"
          width={"20%"}
          borderRadius="7.2px"
          buttonStyle={{
            HEADER2,
            minWidth: "200px",
          }}
          onClick={() => {
            localStorage.setItem("controllerStep", "5")
            props.goToNext("controller_setup", true)
          }}
        />)}
        <CustomButton
          disabled={isNextStepDisable}
          placeHolder={!props.isFromDashBoard ? t("YC_NEXT_STEP") : t("YC_SUBMIT")}
          textTransform="none"
          background={isNextStepDisable ? GREY : BLUE}
          height="40px"
          width={"20%"}
          borderRadius="7.2px"
          buttonStyle={{
            HEADER2,
            minWidth: "200px",
          }}
          onClick={() => {
            nextStep(inputData)
          }}
        />
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          alignContent: "flex-end",
          width: "98%",
          mb: 3,
          mt: 3,
        }}
      >
        <CustomButton
          disabled={isNextStepDisable}
          placeHolder={t("YC_NEXT_STEP")}
          textTransform="none"
          background={isNextStepDisable ? GREY : BLUE}
          height="40px"
          width={"20%"}
          borderRadius="7.2px"
          buttonStyle={{
            HEADER2,
            minWidth: "200px",
          }}
          onClick={() => {
            nextStep(inputData)
          }}
        />
      </Box> */}
    </Box>
  );
}
export default BusinessProcessOne;
