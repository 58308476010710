import { Box, Button, IconButton, SvgIcon, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SettingsIcon from "../../../assets/Settings_fill.png";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { BLACK, WHITE, PINK, DARKGREY, YELLOW } from "utils/colors";
import { useTranslation } from "react-i18next";
import DashboardStatusAccordian from "components/dashboard/dashboard-status-accordian/dashboard-status-accordian";
import { AccordionData } from "components/dashboard/dashboard-status-accordian/AccordianData";
import { useEffect, useState } from "react";

const useStyles = makeStyles(() => ({
  boxView: {
    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
    background: "#FFFFFF",
    minHeight: "266px",
    borderRadius: "8px",
  },
  titleText: {
    color: BLACK,
    fontSize: 20,
    fontWeight: "700",
    marginTop: 15,
  },
  bodyText: {
    display: "flex",
    fontSize: 16,
    marginTop: 1,
    textAlign: "left",
    fontWeight: "700",
    lineHeight: "18px",
  },
  button: {
    minHeight: "29px",
    width: "20%",
    borderRadius: "8px",
    fontSize: 16,
    fontWeight: "700",
    '&:hover': {
      color: BLACK,
      backgroundColor: YELLOW,
    },
  }
}));

function ShortcutSetupProcess(props: any) {
  const { boxView, button, titleText, bodyText } = useStyles();
  const [isAccessButtonClicked, setIsAccessButtonClicked] = useState(false)
  const [statusComplete, setStatusComplete] = useState("0%")
  const { t } = useTranslation()

  // const statusComplete="80%";
  const accordionData: AccordionData[] = [
    {
        accordianTitle: "Business Set Up",
        accordianList: [{
            businessStepOne: "Business step 1",
            businessStepTwo: "Business step 2",
            businessStepThree: "Business step 3",
            businessStepFour: "Business step 4"
        }],
    },
    {
        accordianTitle: "Brand Identity",
        accordianList: [{
            businessLogo: "Upload business Logo",
            businessColor: "Set business colours",
            businessSlogan: "Add business slogan",
        }],
    },
    {
        accordianTitle: "Accounting",
        accordianList: [],
    },
    {
        accordianTitle: "Communication",
        accordianList: [],
    },
    {
        accordianTitle: "Dashboard Overview",
        accordianList: [],
    },
    {
        accordianTitle: "Manage your preferances",
        accordianList: [],
    },

]

useEffect(() => {
  let userId;
  const loginUserDetails = localStorage.getItem("user_Details")
  if (loginUserDetails) {
      const details = JSON.parse(loginUserDetails)
      userId = details.setup_complete_per
      setStatusComplete(details.setup_complete_per)
  }
}, [])

  return (
    <Box className={boxView+" col-12 col-lg-4 m-0 p-0  mt-lg-0 mt-2"}>
      <Typography className={titleText} > {t("YC_STATUS_AND_SHORTCUTS")} </Typography>
      <Box sx={{
        minHeight: "47px",
        width: "100%",
        marginTop: 4,
        borderRadius: "8px",
        bgcolor: DARKGREY,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
      }}
      >
        <IconButton>
          <img src={SettingsIcon} height={30} alt={"icons"} />
        </IconButton>
        <Typography className={bodyText} > {t("YC_SETUP_COMPETED")}: {statusComplete} </Typography>
        <Button variant="contained" className={button} onClick={() => {props.setIsAccessButtonClick(true)}}>{t("YC_ACCESS")}</Button>
      </Box>
      <Box sx={{
        minHeight: "47px",
        width: "100%",
        marginTop: 3,
        borderRadius: "8px",
        bgcolor: PINK,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
      }}
      >
        <IconButton>
          <SvgIcon component={AddCircleRoundedIcon} sx={{ color: WHITE }} inheritViewBox />
        </IconButton>
        <Typography className={bodyText} sx={{color:WHITE}} > {t("YC_TOOLS_AND_APPLICATIONS")} </Typography>
        <Button variant="contained" className={button}>{t("YC_ACCESS")}</Button>
      </Box>
    </Box>
  );
}
export default ShortcutSetupProcess;
