import {
  Alert,
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { BLACK, WHITE, YELLOW } from "utils/colors";
import DraftsIcon from "@mui/icons-material/Drafts";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import BillingIcon from "../../../assets/Billing_fill.png";
import ApplyIcon from "../../../assets/Apply_fill.png";
import DocumentationIcon from "../../../assets/Folder_alt_fill.png";
import SettingsIcon from "../../../assets/Settings_fill.png";
import SupportIcon from "../../../assets/Support_fill.png";
import AppToolsIcon from "../../../assets/Stackframe_fill.png";
import NotificationIcon from "../../../assets/Notification_fill.png";
import UserAccessIcon from "../../../assets/User_access_fill.png";
import TransactionsIcon from "../../../assets/Transactions_fill.png";
import TasksListsIcon from "../../../assets/Task_list_fill.png";
import EditorialIcon from "../../../assets/Editorial_fill.png";
import WidgetsIcon from "../../../assets/Widgets_fill.png";
import OverviewIcon from "../../../assets/Overview_Graph.png";
import CustomiseMenuIcon from "../../../assets/Customise_menu_Icon.png";
import SetupProcess from "../setup-process-steps/setup-process";
import ControllerProcess from "./controller-process";
import { useNavigate } from "react-router-dom";
import BusinessProcess from "../business-setup/business-process";
import YellowPageProcess from "../yellowpage-setup/yellowpage-process";
import { YC_ACCOUNTING_SETUP_MSG, YC_BUSINESS_SETUP_MSG, YC_CONTROLLER_SETUP_MSG, YC_CONTROLLER_SETUP_STEP_3, YC_CONTROLLER_SETUP_STEP_5, YC_YELLOW_PAGES_SETUP_MSG } from "utils/strings";
import AccountingProcess from "../accounting-setup/accounting-process";
import PopUpModal from "components/pop-up-view/popup-modal";
import { useTranslation } from "react-i18next";

const DRAWER_WIDTH = 250;
const useStyles = makeStyles(() => ({
  titleText: {
    color: BLACK,
    fontSize: 20,
    fontWeight: "700",
    marginTop: 1,
  },
  cellView: {
    color: YELLOW,
    background: BLACK,
  },
  cellText: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "space-between",
  },
  dividerStyle: {
    borderColor: WHITE,
    borderWidth: 0.5,
    opacity: 1,
    marginRight: 20,
  },
}));

function ControllerSetupContainer(props: any) {
  const { titleText, cellView, cellText, dividerStyle } = useStyles();
  const [openDashboard, setOpenDashboard] = React.useState(false);
  const [openAppTools, setOpenAppTools] = React.useState(false);
  const [openFunctions, setOpenFunctions] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [setupStep, setSetupStep] = React.useState("controller_setup");
  const [currentSetup, setCurrentSetup] = React.useState("controller_setup")
  const [isControllerSetup, setIsControllerSetup] = React.useState(false)
  const [isBusinessSetup, setIsBusinessSetup] = React.useState(false)
  const [isYellowPageSetup, setIsYellowPageSetup] = React.useState(false)
  const [isAccountingSetup, setIsAccountingSetup] = React.useState(false)
  const [stepNumber, setStepNumber] = React.useState(1)

  const [stepNumberController, setStepNumberController] = React.useState(1)
  const [stepNameOfController, setStepNameOfController] = React.useState(YC_CONTROLLER_SETUP_MSG)

  const [stepNumberBusiness, setStepNumberBusiness] = React.useState(1)
  const [stepNameOfBusinessSetup, setStepNameOfBusinessSetup] = React.useState(YC_BUSINESS_SETUP_MSG)
  const [isBusinessSetupDone, setIsBusinessSetupDone] = React.useState(false)

  const [stepNumberYellowPage, setStepNumberYellowPage] = React.useState(1)
  const [stepNameOfYellowPageSetup, setStepNameOfYellowPageSetup] = React.useState(YC_YELLOW_PAGES_SETUP_MSG)

  const [stepNumberAccounting, setStepNumberAccounting] = React.useState(1)
  const [stepNameOfAccounting, setStepNameOfAccounting] = React.useState(YC_ACCOUNTING_SETUP_MSG)
  const [isOpen, setIsOpen] = React.useState(false)

  const navigate = useNavigate();
  const { t } = useTranslation()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    getPastSetupStepOfUser()
  }, [])

  const getPastSetupStepOfUser = () => {
    const loginUserDetails = localStorage.getItem("user_Details")
    if (loginUserDetails){
        const details = JSON.parse(loginUserDetails)
        // if (details.setup_count === "3" && details.step_count === "1") {
        if (Number(details.setup_count) > 2) {
          setIsBusinessSetupDone(true)
        }
      
        if(details.setup_count === "1"){
          setIsControllerSetup(true)
          setSetupStep("controller_setup")
          setCurrentSetup("controller_setup")
          setStepNumberController(Number(details.step_count))
        } else if(details.setup_count === "2"){
          setSetupStep("business_setup")
          setCurrentSetup("business_setup")
          setStepNumberController(5)
          setIsControllerSetup(true)
          setIsBusinessSetup(true);
          setStepNumberBusiness(details.step_count)
        } else if(details.setup_count === "3"){
          setSetupStep("yellow_page_setup")
          setCurrentSetup("yellow_page_setup")
          setStepNumberController(5)
          setStepNumberBusiness(4)
          setIsControllerSetup(true)
          setIsBusinessSetup(true)
          setIsYellowPageSetup(true)
          setStepNumberYellowPage(details.step_count)
        } else if(details.setup_count === "4"){
          setSetupStep("accounting_setup")
          setCurrentSetup("accounting_setup")
          setStepNumberController(5)
          setStepNumberBusiness(4)
          setStepNumberYellowPage(3)
          setIsControllerSetup(true)
          setIsBusinessSetup(true)
          setIsYellowPageSetup(true)
          setIsAccountingSetup(true);
          setStepNumberAccounting(details.step_count)
        } else {
          setIsControllerSetup(true)
          setSetupStep("controller_setup")
          setCurrentSetup("controller_setup")
          setStepNumberController(1)
        }
        // setStepNumber(Number(details.step_count)+1)
    }
  }

  const togglePopup = () => {
    setIsOpen(!isOpen);
  }
  const renderItem = (itemName: string, icon: any = null, disabled = true) => {
    return (
      <Box>
        <ListItemButton
          className={cellView}
          disabled={disabled}
          sx={icon ? null : { ml: 5 }}
          onClick={() => {
            if (itemName === 'Invite User') {
              navigate('/invite')
            } else if (itemName === "Logout"){
              setIsOpen(true)
              // localStorage.clear();
              // navigate("/")
            }
          }}
        >
          {icon && (
            <ListItemIcon>
              <img src={icon} height={30} alt={"icons"} />
            </ListItemIcon>
          )}
          <Box className={cellText}>
            <Typography>{itemName}</Typography>
          </Box>
        </ListItemButton>
      </Box>
    );
  };

  const renderNestedItem = (
    itemName: string,
    isNested: boolean = false,
    nestedItems: any,
    isOpen: boolean = false,
    icon: any = null,
    disabled = true
  ) => {
    return (
      <Box>
        <ListItemButton
          className={cellView}
          sx={icon ? null : { ml: 5 }}
          disabled={disabled}
          onClick={() => {
            if (itemName === "DASHBOARD") {
              setOpenDashboard(!openDashboard);
              navigate("/incomplete-dashboard")
            }
            else if (itemName === "APPS-TOOLS") setOpenAppTools(!openAppTools);
            else if (itemName === "FUNCTIONS") setOpenFunctions(!openFunctions);
          }}
        >
          {icon && (
            <ListItemIcon>
              <img src={icon} height={40} alt={"icons"} />
            </ListItemIcon>
          )}
          <Box className={cellText}>
            <Typography>{itemName}</Typography>
            {isNested ? (
              isOpen ? (
                <ExpandLess htmlColor="white" />
              ) : (
                <ExpandMore htmlColor="white" />
              )
            ) : null}
          </Box>
        </ListItemButton>
        {isNested &&
          nestedItems.map((item: any) => (
            <Collapse key={`con_list_${item.name}`} in={isOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  className={cellView}
                  sx={{ pl: 4 }}
                  onClick={() => {
                    if (item.name === "User Access") {
                      navigate("/user-access");
                    } else if (item.name === "Customise") {
                      navigate("/dashboard-commonUser");
                    }
                  }}
                >
                  {item.icon && (
                    <ListItemIcon>
                      <img src={item.icon} height={25} alt={"icons"} />
                    </ListItemIcon>
                  )}
                  <ListItemText
                    sx={item.icon ? null : { ml: 7 }}
                    primary={item.name}
                  />
                </ListItemButton>
              </List>
            </Collapse>
          ))}
        <Divider
          orientation="vertical"
          flexItem
          className={dividerStyle}
          sx={{ ml: 2 }}
        />
      </Box>
    );
  };

  const renderSidePanel = (
    <div>
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: BLACK }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {renderNestedItem(
          "DASHBOARD",
          true,
          [
            { name: "Overview", icon: OverviewIcon },
            { name: "Customise", icon: CustomiseMenuIcon }
          ],
          openDashboard,
          "",
          isBusinessSetupDone === true ? false : true
        )}
        {renderNestedItem(
          "APPS-TOOLS",
          true,
          [{ name: "Directory", icon: null }],
          openAppTools,
          AppToolsIcon
        )}
        {renderNestedItem(
          "FUNCTIONS",
          true,
          [
            { name: "Notifications", icon: NotificationIcon },
            { name: "User Access", icon: UserAccessIcon },
            { name: "Transactions", icon: TransactionsIcon },
            { name: "Tasks List", icon: TasksListsIcon },
            { name: "Editorial", icon: EditorialIcon },
            { name: "Widgets", icon: WidgetsIcon },
            { name: "Others", icon: null },
          ],
          openFunctions,
          ""
        )}
        <Divider
          orientation="vertical"
          flexItem
          className={dividerStyle}
          sx={{ ml: 2 }}
        />
        {renderItem("ADMINISTRATION","")}
        {renderItem("Billing", BillingIcon)}
        {renderItem("Apply", ApplyIcon)}
        {renderItem("Documentation", DocumentationIcon)}
        {renderItem("Settings", SettingsIcon)}
        {renderItem("Support", SupportIcon, false)}
        {renderItem("Invite User", UserAccessIcon)}
        {renderItem("Logout", SupportIcon, false)}
        <ListItemButton>
          <ListItemIcon>
            <DraftsIcon />
          </ListItemIcon>
          <ListItemText primary="Drafts" />
        </ListItemButton>
      </List>
    </div>
  );

  const handleControllerSteps = (stepNo: any, isPreStepClicked: boolean) => {
    setStepNumber(stepNo)
    const stepCountController = localStorage.getItem("controllerStep")
    if(stepCountController){
      const count = Number(stepCountController)
      setStepNumberController(count)
      if(count === 1 || count === 2){
        setStepNameOfController(YC_CONTROLLER_SETUP_MSG)
      } else if(count === 3 || count === 4){
        setStepNameOfController(YC_CONTROLLER_SETUP_STEP_3)
      } else {
        setStepNameOfController(YC_CONTROLLER_SETUP_STEP_5)
      }
    }
    

    const stepCountBusiness = localStorage.getItem("businessStep")
    if(stepCountBusiness){
      const count = Number(stepCountBusiness)
      setStepNumberBusiness(count)
      setStepNameOfBusinessSetup(YC_BUSINESS_SETUP_MSG)
    }

    const stepCountYP = localStorage.getItem("yellowPageStep")
    if(stepCountYP){
      const count = Number(stepCountYP)
      setStepNumberYellowPage(count)
      setStepNameOfYellowPageSetup(YC_YELLOW_PAGES_SETUP_MSG)
    }

    const stepCountAccounting = localStorage.getItem("accountingSetup")
    if(stepCountAccounting){
      const count = Number(stepCountAccounting)
      setStepNumberAccounting(count)
      setStepNameOfAccounting(YC_ACCOUNTING_SETUP_MSG)
    }

    if(stepNo === 'controller_setup') {
      setIsControllerSetup(true);
      setCurrentSetup(stepNo)
      setIsBusinessSetupDone(false)
    } else if(stepNo === 'business_setup') {
      setIsBusinessSetup(true);
      setCurrentSetup(stepNo)
      setIsBusinessSetupDone(false)
    } else if(stepNo === 'yellow_page_setup') {
      setIsYellowPageSetup(true);
      setCurrentSetup(stepNo)
      setIsBusinessSetupDone(true)
    } else if(stepNo === 'accounting_setup') {
      setIsAccountingSetup(true);
      setCurrentSetup(stepNo)
      setIsBusinessSetupDone(true)
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Box
        component="nav"
        sx={{ width: { sm: DRAWER_WIDTH } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        {/* <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: "block", sm: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: DRAWER_WIDTH
                  },
                }}
              >
                {drawer}
              </Drawer> */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: DRAWER_WIDTH,
              height: "88%",
              mt: 7,
              background: BLACK,
            },
          }}
          open
        >
          {renderSidePanel}
          {/* {drawer} */}
        </Drawer>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
          mt: 7,
          ml: 5,
        }}
      >
        <Typography className={titleText} sx={{ display: "flex", mb: 1 }}>
          Setup Process
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          {(setupStep === "controller_setup" || isControllerSetup) && (
            <SetupProcess
              setupName={"Controller Setup"}
              setupStepNo={stepNumberController}
              setupTotalSteps={5}
              setupColor={"#0F5CD0"}
              setUpStepName={stepNameOfController}
            />
          )}
          {/* {setupStep === "business_setup" && ( */}
          {isBusinessSetup && (
            <SetupProcess
              setupName={"Business Setup"}
              setupStepNo={stepNumberBusiness}
              setupTotalSteps={4}
              setupColor={"#CA1F1F"}
              setUpStepName={stepNameOfBusinessSetup}
            />
          )}
          {/* {setupStep === "yellow_page_setup" && ( */}
          {isYellowPageSetup && (
            <SetupProcess
              setupName={"Yellow Pages Setup"}
              setupStepNo={stepNumberYellowPage}
              setupTotalSteps={3}
              setupColor={YELLOW}
              setUpStepName={stepNameOfYellowPageSetup}
            />
          )}
          {isAccountingSetup && (
            <SetupProcess
              setupName={"Accounting Setup"}
              setupStepNo={stepNumberAccounting}
              setupTotalSteps={3}
              setupColor={"#03A60A"}
              setUpStepName={stepNameOfAccounting}
            />
          )}
        </Box>
        <Box sx={{ mt: 4, mr: 1 }}>
          <Divider
            orientation="horizontal"
            flexItem
            className={dividerStyle}
            sx={{ mb: 2, mr: 2, marginTop: 0.2 }}
            style={{ borderColor: "rgba(0, 0, 0, 0.5)", borderWidth: 0.5 }}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
        {/* {setupStep === "controller_setup" && (
          <ControllerProcess
            goToNext={handleControllerSteps}
          />
        )} */}
        {currentSetup === "business_setup" && (
          <BusinessProcess
            goToNext={handleControllerSteps}
            stepNumberBusiness={stepNumberBusiness}
          />
        ) 
        }
        {currentSetup === "controller_setup" && (
        <ControllerProcess
            goToNext={handleControllerSteps}
            stepNumberController={stepNumberController}
          />
          )}
          {currentSetup === "yellow_page_setup" && (
        <YellowPageProcess
            goToNext={handleControllerSteps}
            stepNumberYellowPage={stepNumberYellowPage}
          />
          )}
          {currentSetup === "accounting_setup" && (
        <AccountingProcess
            goToNext={handleControllerSteps}
            stepNumberAccounting={stepNumberAccounting}
          />
          )}
        </Box>
      </Box>
      <Modal
              open={isOpen}
              onClose={togglePopup}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div>
              <PopUpModal
                title={"Logout"}
                description={"By clicking yes, you will get logout from the application"}
                handleClose={togglePopup}
                firstbuttonName={t("YC_YES")}
                secondbuttonName={t("YC_NO")}
                firstbuttonAction={() => {
                    localStorage.clear();
                    navigate("/")
                }}
                secondbuttonAction={() => {
                    togglePopup()
                }}
      />
              </div>
            </Modal>
    </Box>
  );
}
export default ControllerSetupContainer;
