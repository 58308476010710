import { RegisterModel, TodoModel } from "models/redux-models";
import BaseApi from "./BaseApi";

export default class HomeApi extends BaseApi {
  static async getOTP(values: any, headers: any) {
    const res = await HomeApi.post(`admin/public/api/auth/request_otp`, values, headers);
    return res;
  }

  static async Verifyotp(values: any, headers: any) {
    const res = await HomeApi.post(`admin/public/api/auth/verify_otp`,values, headers);
    return res.data;
  }

  static async submitRegister(values: RegisterModel, headers: any) {
    const res = await HomeApi.post(`admin/public/api/auth/register`, values, headers);
    return res;
  }

  static async submitLoginForm(values: any, headers: any) {
    const res = await HomeApi.post(`admin/public/api/auth/userLogin`, values, headers);
    return res;
  }
  static async forgotPassword(values: any, headers: any) {
    const res = await HomeApi.post(`admin/public/api/auth/forget-password`, values, headers);
    return res;
  }

  static async getAllTodos() {
    const res = await HomeApi.get("todos");
    return res.data;
  }

  static async getParticularTodo(todo_id: number) {
    const res = await HomeApi.get("todos");
    return res.data.filter((todo: TodoModel) => todo.Id === todo_id)[0];
  }

  static async signUpUser(payload: any) {
    try {
      const res = await HomeApi.post("admin/public/api/auth/enquiry", payload, '')
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async confirmationControllerAccount(payload: any) {
    try {
      const res = await HomeApi.put("admin/public/api/auth/controllerdetails", payload)
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async getUserControllerDetails() {
    const res = await HomeApi.get("admin/public/api/auth/usercontrolerdetails");
    return res.data;
  }

  static async postUserControllerDetails(payload: any) {
    try {
      const res = await HomeApi.put("admin/public/api/auth/post_controldetails", payload)
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async postAdditionalUserDetails(payload: any) {
    try {
      const res = await HomeApi.post("admin/public/api/auth/additional_user", payload, "")
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async postAdditionalUserForNewController(payload: any) {
    try {
      const res = await HomeApi.post("admin/public/api/auth/new_controller_additional_user", payload, "")
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async getUserNameAndRole(userId: number) {
    const res = await HomeApi.get(`admin/public/api/auth/user_role_Name/${userId}`);
    return res.data;
  }

  static async getAdditionalUser(userId: number) {
    const res = await HomeApi.get(`admin/public/api/auth/controller/additional_user/${userId}`);
    return res.data;
  }

  static async postBusinessSetupStepOneDetails(payload: any, userId:number) {
    try {
      const res = await HomeApi.post(`admin/public/api/auth/businessaccount/${userId}`, payload, "")
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async getCapacityList() {
    const res = await HomeApi.get("admin/public/api/auth/capacity");
    return res.data;
  }

  static async updateAddtionalUserDetails(payload: any) {
    try {
      const res = await HomeApi.put("admin/public/api/auth/controller/additional_user_update", payload)
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async uploadStepTwoBusinessSetup(payload: any, userId:number) {
    try {
      const res = await HomeApi.post(`admin/public/api/auth/businessaccount_second_step/${userId}`, payload, "")
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async uploadStepThreeBusinessSetup(payload: any, userId: number) {
    try {
      const res = await HomeApi.post(`admin/public/api/auth/businessaccount_third_step/${userId}`, payload, "")
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async uploadStepFourBusinessSetup(payload: any, headers: any, userId: number) {
    try {
      const res = await HomeApi.post(`admin/public/api/auth/businessaccount_four_step/${userId}`, payload, headers)
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }
  
  static async getCountryList() {
    const res = await HomeApi.get("admin/public/api/auth/countries");
    return res.data;
  }

  static async getRegionData(countryCode: string) {
    const res = await HomeApi.get(`admin/public/api/auth/region/${countryCode}`);
    return res.data;
  }

  static async getCityData(regionCode: string) {
    const res = await HomeApi.get(`admin/public/api/auth/cities/${regionCode}`);
    return res.data;
  }

  static async getMainCategoryData() {
    const res = await HomeApi.get("admin/public/api/auth/mainCategories");
    return res.data;
  }

  static async getSubCategoryData(mainCategoryId: string) {
    const res = await HomeApi.get(`admin/public/api/auth/subCategories/${mainCategoryId}`);
    return res.data;
  }

  static async getChildCategoryData(mainCategoryId: string, subCategoryId: string) {
    const res = await HomeApi.get(`admin/public/api/auth/childCategories/${mainCategoryId}/${subCategoryId}`);
    return res.data;
  }

  static async getBusinessModuleData() {
    const res = await HomeApi.get("admin/public/api/auth/module");
    return res.data;
  }

  static async resetPassword(payload: any) {
    try {
      const res = await HomeApi.post("admin/public/api/auth/reset-password", payload, '')
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async updateInviteUserDetails(payload: any) {
    try {
      const res = await HomeApi.putWithoutToken("admin/public/api/auth/update_userInvitation", payload)
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async createPassword(payload: any) {
    try {
      const res = await HomeApi.putWithoutToken("admin/public/api/auth/createPassword", payload)
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async uploadYellowPageFirstStepData(payload: any, userId: number) {
    try {
      const res = await HomeApi.post(`admin/public/api/auth/yellowpages_first_step/${userId}`, payload, '')
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async uploadYellowPageSecondStepData(payload: any, userId: number) {
    try {
      const res = await HomeApi.put(`admin/public/api/auth/yellowpages_second_step/${userId}`, payload)
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async uploadAccountStepFirstStepData(payload: any, userId: number) {
    try {
      const res = await HomeApi.post(`admin/public/api/auth/account_step_one/${userId}`, payload, '')
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async uploadAccountSecondStepData(payload: any, userId: number) {
    try {
      const res = await HomeApi.put(`admin/public/api/auth/account_step_two/${userId}`, payload)
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async getTermsAndConditions(userId: number) {
    const res = await HomeApi.get(`admin/public/api/auth/terms_cond_doc/${userId}`);
    return res.data;
  }


  static async uploadAccountThirdStepData(payload: any, headers: any, userId: number) {
    try {
      const res = await HomeApi.post(`admin/public/api/auth/account_step_three/${userId}`, payload, headers)
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async getRole(userId: number) {
    const res = await HomeApi.get(`admin/public/api/auth/role`);
    return res.data;
  }

  static async getPrimaryRole(userId: number) {
    const res = await HomeApi.get(`admin/public/api/auth/primary_role`);
    return res.data;
  }

  static async getOtherRole(userId: number) {
    const res = await HomeApi.get(`admin/public/api/auth/other_role`);
    return res.data;
  }

  static async updateExistingUserRole(payload: any) {
    try {
      const res = await HomeApi.put("admin/public/api/auth/change_existing_user_role", payload)
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async uploadUserProfilePhoto(payload: any, headers: any, userId: number) {
    try {
      const res = await HomeApi.post(`admin/public/api/auth/update_photo/${userId}`, payload, headers)
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async UploadPersonalOption(payload: any, userId: number) {
    try {
      const res = await HomeApi.put(`admin/public/api/auth/personal_option/${userId}`, payload)
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async UploadContactInfo(payload: any, userId: number) {
    try {
      const res = await HomeApi.put(`admin/public/api/auth/contact_info/${userId}`, payload)
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async getUserList(userId: number) {
    const res = await HomeApi.get(`admin/public/api/auth/user_list/${userId}`);
    return res.data;
  }

  static async getUserProfilePhoto(userId: number) {
    const res = await HomeApi.get(`admin/public/api/auth/getProfilePhoto/${userId}`);
    return res.data;
  }

  static async getApplicationList() {
    const res = await HomeApi.get(`admin/public/api/auth/application_list`);
    return res.data;
  }

  static async getUserSearchByEmail(userEmail: string) {
    const res = await HomeApi.get(`admin/public/api/auth/search_by_email/${userEmail}`);
    return res.data;
  }

  static async createRole(payload: any) {
    try {
      const res = await HomeApi.post(`admin/public/api/auth/create_role`, payload, "")
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async addNewUser(payload: any, userId: number) {
    try {
      const res = await HomeApi.post(`admin/public/api/auth/add_new_user/${userId}`, payload, "")
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async suspendRole(payload: any) {
    try {
      const res = await HomeApi.put(`admin/public/api/auth/suspend_role`, payload)
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async deleteRole(payload: any) {
    try {
      const res = await HomeApi.post(`admin/public/api/auth/delete_role`, payload, "")
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async sendOtpPassword(payload: any) {
    try {
      const res = await HomeApi.post(`admin/public/api/auth/send_otp_password`, payload, "")
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async verifyOTPPassword(payload: any) {
    try {
      const res = await HomeApi.post(`admin/public/api/auth/verify_otp_password`, payload, "")
      return res
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async isSetUpComplete(payload: any, userId: number) {
    try {
      const res = await HomeApi.put(`admin/public/api/auth/isSetupComplete/${userId}`, payload)
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async getPersonCountByRole(userId: string) {
    const res = await HomeApi.get(`admin/public/api/auth/get_person_by_role/${userId}`);
    return res.data;
  }

  static async postSetupStepCount(payload: any) {
    try {
      const res = await HomeApi.put("admin/public/api/auth/setUp_count", payload)
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async getRecaptcha() {
    const res = await HomeApi.get(`admin/public/api/auth/recaptchKey`);
    return res.data;
  }

  static async getAccountList() {
    const res = await HomeApi.get(`admin/public/api/auth/account_list`);
    return res.data;
  }

  static async getPositionOfController() {
    const res = await HomeApi.get(`admin/public/api/auth/position_controller_list`);
    return res.data;
  }

  static async postOptionAddOtherUser(payload: any) {
    try {
      const res = await HomeApi.put("admin/public/api/auth/additionaluser_add_other_user", payload)
      return res.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async getLegalStatusList() {
    const res = await HomeApi.get(`admin/public/api/auth/legalstatus_list`);
    return res.data;
  }

  static async getControllerSetupData(userId: string) {
    const res = await HomeApi.get(`admin/public/api/auth/get_controllersetup_step_second/${userId}`);
    return res.data;
  }

  static async getBusinessSetupData(userId: string) {
    const res = await HomeApi.get(`admin/public/api/auth/get_bussiness_setup_first_step/${userId}`);
    return res.data;
  }

  static async getYellowPageSetupData(userId: string) {
    const res = await HomeApi.get(`admin/public/api/auth/get_yellowpages_setup_data/${userId}`);
    return res.data;
  }

  static async getAccountingSetupData(userId: string) {
    const res = await HomeApi.get(`admin/public/api/auth/get_accounting_setup/${userId}`);
    return res.data;
  }  
  
  static async getPermissionList() {
    const res = await HomeApi.get("admin/public/api/auth/get_permission")
    return res.data;
  }

  static async getCapacityBySearch(text: string) {
    const res = await HomeApi.get(`admin/public/api/auth/fillter_permission/${text}`)
    return res.data;
  }

  static async postPermissionCapacityData(payload: any) {
    try {
      const res = await HomeApi.post(`admin/public/api/auth/post_permission`, payload, "")
      return res
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async getCapacityByApplicaytionId(applicationId: string, roleId: string) {
    const res = await HomeApi.get(`admin/public/api/auth/application_capcity/${applicationId}/${roleId}`)
    return res.data;
  }

  static async getCapacityRoleByUserID(userId: string) {
    const res = await HomeApi.get(`admin/public/api/auth/option_capacity_user_role_setup/${userId}`)
    return res.data;
  }

  static async postDuplicateRoleData(payload: any) {
    try {
      const res = await HomeApi.post(`admin/public/api/auth/duplicate_role_setup`, payload, "")
      return res
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async getAssignPermissionByRoleAndApplication(applicationId: string, roleId: string) {
    const res = await HomeApi.get(`admin/public/api/auth/get_assign_permission/${applicationId}/${roleId}`)
    return res.data;
  }

  static async getApplicationPlan(applicationId: string) {
    const res = await HomeApi.get(`admin/public/api/auth/plan_application_get/${applicationId}`)
    return res.data;
  }

  static async getResourseManagementData(pageName: string) {
    const res = await HomeApi.get(`admin/public/api/auth/role_resourse_management/${pageName}`)
    return res.data;
  }

}