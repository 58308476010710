import React, { useEffect } from "react";
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignUpPage from "views/signup/signuppage";
import Homepage from "./views/homepage/homepage";
import ManagePage from "views/homepage/manage/managepage";
import PartnersPage from "views/homepage/partners/partnerspage";
import ServicePage from "views/homepage/service/servicepage";
import WhyPage from "views/homepage/why/whypage";
import Dashboard from "views/dashboard/dashboard";
import DashboardStatus from "views/dashboard/dashboard-status";
import 'bootstrap/dist/css/bootstrap.css';
import DashboardComplete from "views/dashboard/dasheboard-new/complete-dashboard";
import ControlPage from "views/homepage/control/controlpage";
import UserAccessDash from "views/dashboard/user-access-dash";
import UserInvitation from "views/user-invitation/userinvitationpage";
import { ToastContainer } from 'react-toastify';
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import { toggleLoader } from "store/actions/loader-action";
import Loader from "components/loder/Loader";
import ProtectedRoute from "services/ProtectedRoute";
import WellCome from "views/dashboard/well-come/wellCome";
import CommonUserDashboard from "views/dashboard/common-user-dashboard/CommonUserDashboard";
import ResetPasswordPage from "views/forgotpassword/resetPassword";
import { Suspense } from 'react';
import HomeApi from "services/HomeAPi";

import { pdfjs } from 'react-pdf';
import ApplicationDash from "views/dashboard/application/application-dash";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

interface Props {
  toggleLoader: any
  loader: { isLoaderShow: false }
}

interface State {
}

class App extends React.Component<Props, State> {
  state = {siteKey: "6Lct7xcqAAAAAGbna9-xTHfbV9DhWyUSH_Y9R88c"}
   getRecatchaKey = async () => {
      const response = await HomeApi.getRecaptcha();
      if (response.status === true) {
          this.setState({siteKey: response.data.site_key})
      }
    }
  componentDidMount(): void {
    const self = this;
    // this.getRecatchaKey()
    axios.interceptors.request.use(config => {
      // alert("request sent successfully");
      self.props.toggleLoader(true)
      return config;
    }, error => {
      self.props.toggleLoader(false)
      return Promise.reject(error)
    });
    axios.interceptors.response.use(config => {
      // alert("Response sent successfully");
      self.props.toggleLoader(false)

      return config;
    }, error => {
      self.props.toggleLoader(false)
      return Promise.reject(error)
    });
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    console.log(this.props.loader)
  }
  render() {
    const { loader } = this.props;

    const userEmail = window.location.href.split('/')[4]
    localStorage.setItem('emailFromUrl', userEmail)

    const inviteUser_name = window.location.href.split('/')[4]
    const inviteUser_lastName = window.location.href.split('/')[5]
    const inviteUser_email = window.location.href.split('/')[6]
    const inviteUser_mobile = window.location.href.split('/')[7]
    
    return (
      <Suspense fallback='loading'>
      <div className="App">
        {loader.isLoaderShow && <Loader />}

        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route index={true} element={<Homepage />} />
              <Route index={false} path="control" element={<ControlPage />} />
              <Route index={false} path="manage" element={<ManagePage />} />
              <Route index={false} path="partners" element={<PartnersPage />} />
              <Route index={false} path="service" element={<ServicePage />} />
              <Route index={false} path="why" element={<WhyPage />} />
              <Route index={false} path="dashboard" element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              } />
              <Route index={false} path={`/invite/${inviteUser_name}/${inviteUser_lastName}/${inviteUser_email}/${inviteUser_mobile}`} 
                 element={
                 <UserInvitation
                    firstName = {inviteUser_name}
                    lastName = {inviteUser_lastName}
                    email = {inviteUser_email}
                    mobilePhone = {inviteUser_mobile}
                    siteKey={this.state.siteKey}
                 />} 
              />
              <Route index={false} path="incomplete-dashboard" element={
                <ProtectedRoute>
                  <DashboardStatus />
                </ProtectedRoute>
              } />
              <Route index={false} path="dashboard-new" element={
                <ProtectedRoute>
                  <DashboardComplete />
                </ProtectedRoute>
              } />
              <Route index={false} path="dashboard-commonUser" element={
                <ProtectedRoute>
                  <CommonUserDashboard />
                </ProtectedRoute>
              } />
              <Route index={false} path="application-dash" element={
                <ProtectedRoute>
                  <ApplicationDash />
                </ProtectedRoute>
              } />
              <Route index={false} path="user-access" element={
                <ProtectedRoute>
                  <UserAccessDash />
                </ProtectedRoute>
              } />
              <Route index={false} path="wellCome" element={
                <ProtectedRoute>
                  <WellCome />
                </ProtectedRoute>
              } />
            </Route>
            <Route path="/signup" element={<SignUpPage />} />
            <Route path={`/reset-password/${userEmail}`} element={<ResetPasswordPage />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </div>
      </Suspense>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    loader: state.loader
  }
}
export default connect(mapStateToProps, {
  toggleLoader
})(App);