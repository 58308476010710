export const HEADER1 = {
    fontStyle: "normal",
    fontWeight: 'bold',
    fontSize: 35
}

export const HEADER2 = {
    fontStyle: "normal",
    fontWeight: 'bold',
    fontSize: 19,
}

export const SUBHEADER1 = {
    fontFamily: "roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
}

export const SUBHEADER2 = {
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: 16
}

export const BODY1 = {
    fontStyle: "normal",
    fontWeight: 200,
    fontSize: 16
}

export const BODY2 = {
    fontStyle: "normal",
    fontWeight: 100,
    fontSize: 16
}

export const BODY = {
    fontFamily: 'roboto',
    fontSize: 24,
    fontWeight: 400,
}

export const SUBHEADERBOLD = {
    fontFamily: 'roboto',
    fontSize: 20,
    fontWeight: 700,
}

export const SUBHEADERBOLD2 = {
    fontFamily: 'roboto',
    fontSize: 16,
    fontWeight: 700,
}
