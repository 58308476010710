import {
    Avatar,
    Box,
    InputLabel,
    Typography,
} from "@mui/material";
import CustomButton from "components/custom-button/custom-button";
import { BLACK, BLUE, GREY, RED, WHITE } from "utils/colors";
import StepInformation from "../dashboard-reusables/step-information";
import { HEADER2 } from "utils/fonts";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import HomeApi from "services/HomeAPi";
import Notification from "views/Notifications/Notification";
import { useTranslation } from "react-i18next";
import ImageUploadDisplay from "components/file-uploader/image-loaderDisplay";
// import { UC_BUSINESS_SETUP_STEP_FOUR_INFO_1, UC_BUSINESS_SETUP_STEP_FOUR_INFO_RED_1, UC_ENTIRE_PRIME_COLOUR, UC_ENTIRE_SECONDARY_COLOUR, UC_SET_CODE, UC_UPLOAD_COVER_PHOTO, UC_UPLOAD_LOGO, YC_BUSINESS_SETUP_STEP_FOUR_TITLE } from "utils/strings";

const useStyles = makeStyles(() => ({
    titleText: {
        color: BLACK,
        fontSize: 20,
        fontWeight: "bold",
        marginTop: 1,
    },
}));

function BusinessProcessFour(props: any) {
    const { titleText } = useStyles();
    const { t } = useTranslation()
    const [profilePhoto, setProfilePhoto] = useState<any | null>();
    const [logoImageAdded, setLogoImageAdded] = useState(false)
    const [coverImageAdded, setCoverImageAdded] = useState(false)
    const [logoPath, setLogoPath] = useState("")
    const [coverPhotoPath, setCoverPhotoPath] = useState("")
    const [inputData, setInputData] = useState({
        logo: "",
        coverPhoto: "",
        colorCode: "#0d0d0d",
        secondColorCode: "#0d0d0d"
    });

    useEffect(() => {
        getBusinessSetupData()
      }, [])

    const getUserId = () => {
        let userId;
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails){
          const details = JSON.parse(loginUserDetails)
          userId = details.id
        }
        return userId
      }

    const nextStep = async () => {
        localStorage.setItem("businessStep", "4")
        localStorage.setItem("yellowPageStep", "1")
        let userId;
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails) {
            const details = JSON.parse(loginUserDetails)
            userId = details.id
        }

        let formData = new FormData();    //formdata object
        formData.append('logo', logoImageAdded ? inputData.logo[0] : inputData.logo);   //append the values with key, value pair
        formData.append('cover_photo', coverImageAdded ? inputData.coverPhoto[0] : inputData.coverPhoto);
        formData.append('color_setting', inputData.colorCode)
        formData.append('sec_color_setting', inputData.secondColorCode)
        formData.append('setup_count', "3")
        formData.append('step_count', "1")
        formData.append('setup_complete_per', "50%")
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        const response = await HomeApi.uploadStepFourBusinessSetup(formData, config, userId);
        if (response.status === true) {
            Notification.notifySuccess(response.message)
            props.isFromDashBoard ?  props.setIsShowBusinessStepFourModal(false) : props.goToNext("yellow_page_setup");
            emptyData();
        } else {
            Notification.notifyError(response.message)
        }
    };

    const getBusinessSetupData = async () => {
        const response = await HomeApi.getBusinessSetupData(getUserId())
        if(response.status === true){
           setProfilePhoto(response.data.logo_path);
           setLogoPath(response.data.logo_path)
           setCoverPhotoPath(response.data.cover_photo_path)
           let logoPhoto: any
           const logoData = fetch(response.data.logo_path)
            .then((res) => res.blob())
            .then((myBlob) => {debugger
                logoPhoto = new File([myBlob], 'image.jpeg', {type: myBlob.type});
                setInputData((prevFields) => ({
                    ...prevFields,
                    logo: logoPhoto,
                }))
            });

            let imageCoverPhoto: any
            fetch(response.data.cover_photo_path)
            .then((res) => res.blob())
            .then((myBlob) => {
                imageCoverPhoto = new File([myBlob], 'image.jpeg', {type: myBlob.type});
                setInputData((prevFields) => ({
                    ...prevFields,
                    coverPhoto: imageCoverPhoto,
                }))
            });
                         
        setInputData((prevFields) => ({
            ...prevFields,
            colorCode: response.data.color_setting,
            secondColorCode: response.data.sec_color_setting
        }))

        }
    }

    const emptyData = () => {
        setInputData({
            logo: "",
            coverPhoto: "",
            colorCode: "",
            secondColorCode: ""
        })

    }

    const setLogoData = (data: any) => {
        setLogoImageAdded(true)
        setInputData((prevFields) => ({
            ...prevFields,
            logo: data,
        }))
    }

    const setCoverPhotoData = (data: any) => {
        setCoverImageAdded(true)
        setInputData((prevFields) => ({
            ...prevFields,
            coverPhoto: data,
        }))
    }

    const ColorPickerGfg = (key: string, value: string) => {
        return (
            <div style={{
                width: '100%',
                height: '100%',
                borderStyle: 'solid',
                borderWidth: '0.5px',
                borderRadius: 5
            }}
            >
                <input
                    type="color"
                    style={{
                        width: '100%',
                        height: '100%'
                    }}
                    value={value}
                    onChange={(event) => {
                        if (key === 'colorCode') {
                            setInputData((prevFields) => ({
                                ...prevFields,
                                colorCode: event.target.value,
                            }))
                        } else if (key === 'secondColorCode') {
                            setInputData((prevFields) => ({
                                ...prevFields,
                                secondColorCode: event.target.value,
                            }))
                        }
                    }} />
            </div>
        )
    }


    const dummyView = () => {
        return <Box sx={{ display: "flex", minWidth: 80, height: 10 }} />;
    };

    const informationView = () => {
        return (
            <Box sx={{
                display: "flex", flexDirection: "column",
                width: { xs: '75%', sm: '88%', lg: '100%', xl: '100%' },
                pr: 3,
                alignItems: { xs: 'flex-start', sm: 'flex-start', lg: 'flex-end', xl: 'flex-end' }
            }}>
                <StepInformation
                    bgColor={"rgba(15, 92, 208, 0.8)"}
                    width={'100%'}
                    minWidth={"95%"}
                    textColor={WHITE}
                    textOne={t("UC_BUSINESS_SETUP_STEP_FOUR_INFO_1")}
                />
                <Box sx={{ display: "flex", minWidth: 40, height: 20 }} />
                <StepInformation
                    bgColor={RED}
                    width={'100%'}
                    minWidth={"95%"}
                    textColor={WHITE}
                    textOne={t("UC_BUSINESS_SETUP_STEP_FOUR_INFO_RED_1")}
                />
            </Box>
        )
    }

    return (
        <Box
            sx={{
                m: 3,
                pr: 5,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
            }}
        >
            <Typography className={titleText} sx={{ display: "flex", mb: 5 }}>
                {t("YC_BUSINESS_SETUP_STEP_FOUR_TITLE")}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'column', lg: 'row', xl: 'row' }, width: "100%" }}>
                <Box
                    sx={{
                        display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
                        flexDirection: "column",
                        width: "100%",
                        minWidth: { xs: "100%", sm: "100%", lg: "0%", xl: "0%" }
                    }}
                >
                    <Box sx={{
                        width: { xs: "100%", sm: "80%", lg: "80%", xl: "80%" },
                        display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
                        flexDirection: "row",
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderRadius: 2
                    }}
                    >
                        <InputLabel style={{ width: '65%', textAlign: 'start', paddingTop: '4%', paddingLeft: 10 }}>{t("UC_UPLOAD_LOGO")}</InputLabel>
                        {/* <Box sx={{
                            display: "block",
                            flexDirection: "column",
                            paddingBottom: "10px",
                            marginRight: 3,
                            width: 40, 
                            height: 40,
                            background: "red"
                        }}>
                            <Avatar variant="square" src={profilePhoto} sx={{ width: 40, height: 40 }} />
                        </Box> */}
                        <Box sx={{
                            width: { xs: "100%", sm: "100%", lg: "35%", xl: "35%" },
                            height: '60px',
                            borderStyle: 'solid',
                            borderWidth: '0.5px',
                            borderRadius: 2
                        }}
                        >
                            <ImageUploadDisplay
                                width='100%'
                                height='100%'
                                onDrop={setLogoData}
                                maxLength={12}
                                imageUrl={logoPath}
                                imageAdded={inputData.logo}
                                imageKey={"logo"}
                                isMarginLeftSet={props.isFromDashBoard ? true : false}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", minWidth: 80, height: 30 }} />
                    <Box sx={{
                        width: { xs: "100%", sm: "80%", lg: "80%", xl: "80%" },
                        display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
                        flexDirection: "row",
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderRadius: 2
                    }}
                    >
                        <InputLabel style={{ width: '65%', textAlign: 'start', paddingTop: '4%', paddingLeft: 10 }}>{t("UC_UPLOAD_COVER_PHOTO")}</InputLabel>
                        <Box sx={{
                            width: { xs: "100%", sm: "100%", lg: "35%", xl: "35%" },
                            height: '60px',
                            borderStyle: 'solid',
                            borderWidth: '0.5px',
                            borderRadius: 2
                        }}
                        >
                            <ImageUploadDisplay
                                width='100%'
                                height='100%'
                                onDrop={setCoverPhotoData}
                                maxLength={12}
                                imageUrl={coverPhotoPath}
                                imageAdded={inputData.coverPhoto}
                                imageKey={"coverphoto"}
                                isMarginLeftSet={props.isFromDashBoard ? true : false}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", minWidth: 80, height: 30 }} />
                    <Box sx={{
                        width: { xs: "100%", sm: "80%", lg: "80%", xl: "80%" },
                        display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
                        flexDirection: "row",
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderRadius: 2
                    }}
                    >
                        <InputLabel style={{ width: '65%', textAlign: 'start', paddingTop: '4%', paddingLeft: 10 }}>{t("UC_ENTIRE_PRIME_COLOUR")}</InputLabel>
                        <Box sx={{
                            width: { xs: "100%", sm: "100%", lg: "35%", xl: "35%" },
                            height: '60px',
                            borderStyle: 'solid',
                            borderWidth: '0.5px',
                            borderRadius: 2
                        }}
                        >
                            {ColorPickerGfg("colorCode", inputData.colorCode)}
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", minWidth: 80, height: 30 }} />
                    <Box sx={{
                        width: { xs: "100%", sm: "80%", lg: "80%", xl: "80%" },
                        display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
                        flexDirection: "row",
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderRadius: 2
                    }}
                    >
                        <InputLabel style={{ width: '65%', textAlign: 'start', paddingTop: '4%', paddingLeft: 10 }}>{t("UC_ENTIRE_SECONDARY_COLOUR")}</InputLabel>
                        <Box sx={{
                            width: { xs: "100%", sm: "100%", lg: "35%", xl: "35%" },
                            height: '60px',
                            borderStyle: 'solid',
                            borderWidth: '0.5px',
                            borderRadius: 2
                        }}
                        >
                            {ColorPickerGfg('secondColorCode', inputData.secondColorCode)}
                        </Box>
                    </Box>
                    {dummyView()}
                </Box>
                {informationView()}
            </Box>
            <div style={{ marginTop: 10 }} />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: 'row',
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "flex-end",
                    width: "98%",
                    mb: 3,
                    mt: 3
                }}
            >
                {!props.isFromDashBoard && (<CustomButton
                    placeHolder={`${"<" + " " + t("YC_PREVIOUS_STEP")}`}
                    textTransform="none"
                    background={GREY}
                    height="40px"
                    width={"20%"}
                    borderRadius="7.2px"
                    buttonStyle={{
                        HEADER2,
                        minWidth: "200px",
                    }}
                    onClick={() => {
                        localStorage.setItem("businessStep", "3")
                        props.goToNext(3, true)
                    }}
                />
                )}
                <CustomButton
                    placeHolder={t("YC_COMPLETE_SETUP")}
                    textTransform="none"
                    background={BLUE}
                    height="40px"
                    width={"20%"}
                    borderRadius="7.2px"
                    buttonStyle={{
                        HEADER2,
                        minWidth: "200px",
                    }}
                    onClick={() => {
                        nextStep()
                    }}
                />
            </Box>
            {/* <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    alignContent: "flex-end",
                    width: "98%",
                    mb: 3,
                    mt: 3,
                }}
            >
                <CustomButton
                    placeHolder={t("YC_COMPLETE_SETUP")}
                    textTransform="none"
                    background={BLUE}
                    height="40px"
                    width={"20%"}
                    borderRadius="7.2px"
                    buttonStyle={{
                        HEADER2,
                        minWidth: "200px",
                    }}
                    onClick={() => {
                        nextStep()
                    }}
                />
            </Box> */}
        </Box>
    );
}
export default BusinessProcessFour;
